import React, { Children, Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Button, Link, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Slider, { Settings } from 'react-slick';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import ChevronRight from 'src/icons/ChevronRight';
import ChevronLeft from 'src/icons/ChevronLeft';
import ViewAllButton from './ViewAllButton';
import { SxProps } from '@material-ui/system';
import CustomSection from './layout/CustomSection';

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: theme.spacing(1.5),
    padding: '0px',
    display: 'flex',
    // flexWrap: "wrap",
    gap: 10,
    alignItems: 'center',
    // marginBottom: "10px",
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
    },
  },
  containerTour: {
    marginTop: theme.spacing(1),
    padding: '0px',
    display: 'flex',
    gap: 10,
    alignItems: 'center',
  },
  containerGrid: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    alignItems: 'center',
  },
  containerCustom: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  },
  heading: {
    color: '#0A2540',
    lineHeight: '23.35px',
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
  },
  buttonContainer: {
    width: '100%',
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  arrowContainer: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  sliderContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: '0!important',
      padding: '0!important',
    },
  },
  btnBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface WrapperProps {
  children: ((ref: Slider | null) => ReactNode) | ReactNode;
  heading: string;
  button?: String;
  toShow?: number;
  hasArrow?: boolean;
  isFramed?: boolean;
  custom?: string;
  setCurrentTab?: Dispatch<SetStateAction<string>>;
  setRef?: ((ref: Slider | null) => void) | undefined;
  length?: number;
  link?: string;
  autoplay?: boolean;
  sx?: SxProps;
  gap?: number;
}

const SliderWrapper = ({ children, heading, button, toShow, custom, sx, length, link, autoplay, gap }: WrapperProps) => {
  const classes = useStyles();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isSwipeDisabled, setIsSwipeDisabled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: toShow || 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: autoplay,
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 3,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: false,
        },
      },
    ],
  };

  const sliderRef = useRef<Slider>(null);
  const [slickSlider, setSlider] = useState<Slider>(null);

  // @ts-ignore
  const currentBreakpoint = slickSlider?.state.breakpoint;
  // @ts-ignore
  const currentSlidesToShow = slickSlider?.props.responsive?.find((i) => i.breakpoint === currentBreakpoint)?.settings.slidesToShow ?? slickSlider?.props.slidesToShow ?? 4;
  const disableLeftButton = currentSlide <= 0;
  const disableRightButton = currentSlide >= (length ?? 0) - currentSlidesToShow;
  const navigate = useNavigate();

  useEffect(() => {
    setSlider(sliderRef.current);
  }, []);

  useEffect(() => {
    const getParentElements = (element: HTMLElement): HTMLElement[] => {
      const parents: HTMLElement[] = [];
      let currentElement: HTMLElement | null = element.parentElement;

      while (currentElement) {
        parents.push(currentElement);
        currentElement = currentElement.parentElement;
      }

      return parents;
    };

    const handleMouseOver = (event: MouseEvent | TouchEvent) => {
      const target = event.target as HTMLElement;
      const parents = getParentElements(target);
      const isHoveringScrollContainer = !!parents.find((parent) => parent.classList.contains('indiana-scroll-container'));
      setIsSwipeDisabled(isHoveringScrollContainer);
    };

    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  return (
    <CustomSection
      title={heading}
      gap={gap}
      sx={sx}
      titleRightSideElement={
        length > 0 && (
          <Box className={classes.buttonContainer}>
            {button && custom === 'GroupGrid' && (
              <Box
                sx={{
                  display: { xs: 'flex' },
                  alignItems: { xs: 'center' },
                  order: { xs: 3 },
                  gap: '24px',
                }}
              >
                <Link component={RouterLink} to={link}>
                  <Button
                    sx={{
                      border: '1px solid #E0E0E0',
                      color: '#1B3861',
                      padding: '10px 28px',
                      height: '37px',
                    }}
                    variant="outlined"
                  >
                    <ReactSVG src="/images/print.svg" style={{ paddingRight: '5px', paddingTop: '5px' }} />
                    {button}
                  </Button>
                </Link>
              </Box>
            )}
            {button && length > 0 && custom !== 'GroupGrid' && <ViewAllButton text={button} onClick={() => navigate(link ?? '#')} isTournament={custom === 'tournament'} />}
          </Box>
        )
      }
    >
      <Box
        className={length && length !== 0 ? 'test' : ''}
        sx={{ position: 'relative' }}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => isMouseDown && setIsSwiping(true)}
        onMouseUp={() => {
          setIsSwiping(false);
          setIsMouseDown(false);
        }}
        onMouseLeave={() => {
          setIsSwiping(false);
          setIsMouseDown(false);
        }}
      >
        <Slider ref={sliderRef} {...settings} swipe={!isSwipeDisabled}>
          {Children.map(typeof children === 'function' ? children(slickSlider) : children, (child) => (
            <Box sx={{ pointerEvents: isSwiping ? 'none' : 'auto' }}>{child}</Box>
          ))}
        </Slider>
        {length && slickSlider && length > slickSlider.props.slidesToShow && !isMobile ? (
          <>
            <CustomButton
              onClick={() => slickSlider.slickPrev()}
              disabled={disableLeftButton}
              shape="circle"
              variant="outline"
              size="medium"
              sx={{ top: '50%', left: 6, position: 'absolute', transform: 'translate(-50%, -50%)', ':disabled': { opacity: 0.4 } }}
            >
              <ChevronLeft />
            </CustomButton>
            <CustomButton
              onClick={() => slickSlider.slickNext()}
              disabled={disableRightButton}
              shape="circle"
              variant="outline"
              size="medium"
              sx={{ top: '50%', right: 6, position: 'absolute', transform: 'translate(50%, -50%)', ':disabled': { opacity: 0.4 } }}
            >
              <ChevronRight />
            </CustomButton>
          </>
        ) : (
          ''
        )}
      </Box>
    </CustomSection>
  );
};

export default SliderWrapper;
