import { ApolloClient, ApolloLink, InMemoryCache, ServerError } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { onError } from '@apollo/client/link/error';
import { eventEmitter } from 'src/constants';

const cache = new InMemoryCache({
  typePolicies: {
    // CategorySetting: { keyFields: ['isRegistered']},

    Query: {
      fields: {
        //  * NEED TO DEFINE WHAT TYPE OF DATA WILL BE MERGED\
        licenses: {
          merge: (existing: any, incoming: any) => incoming,
        },
        userLicenses: {
          merge: (existing: any, incoming: any) => incoming,
        },
        createTournamentRegistration: {
          merge: (existing: any, incoming: any) => incoming,
        },
        // tournament: {
        //   merge: (existing: any, incoming: any) => existing,
        // },
        // tournamentTopFivePlayers: {
        //   merge: (existing: any, incoming: any) => incoming,
        // },
      },
    },
  },
});
// const URL = 'https://tornated-dev-094f13c08ec4.herokuapp.com/graphql';
// const URL = process.env.REACT_APP_BACKEND_URL;
// const URL = "https://tornated-dev-094f13c08ec4.herokuapp.com/graphql";
const URL = process.env.REACT_APP_BACKEND_URL;
// const URL = 'https://vertexo-cd8e69cd5c9d.herokuapp.com/graphql';
// const URL = 'https://api.tournated.com/graphql';
// const URL = 'https://staging-api.tournated.com/graphql';
// const URL = 'https://3196-2400-adc5-476-fa00-c488-adc4-a78b-6cff.ngrok-free.app/graphql';

// const URL = 'http://localhost:3000/graphql';

const authLink = setContext((_, { headers }) => {
  // * GET THE AUTHENTICATION TOKEN FROM LOCAL STORAGE IF IT EXISTS
  const token = Cookies.get('token');
  // * RETURN THE HEADERS TO THE CONTEXT SO HTTP-LINK CAN READ THEM
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'apollo-require-preflight': true,
    },
  };
});

const httpLink = createUploadLink({
  uri: URL,
});
const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    // Check if the networkError is an instance of ServerError and has a status code
    if ((networkError as ServerError).statusCode) {
      const statusCode = (networkError as ServerError).statusCode;

      // Emit 'serverError' only for server crash errors (500)
      if (statusCode === 500) {
        console.error('Server crash error occurred:', networkError);
        eventEmitter.emit('serverError'); // Emit event only for server crash
      } else {
        console.error('Network error occurred:', networkError);
      }
    } else if (networkError.message.includes('Failed to fetch') || networkError.message.includes('CORS')) {
      // Handle CORS error or fetch failure
      console.error('CORS error or network fetch failure:', networkError);
      eventEmitter.emit('serverError'); // Emit event for CORS issues or when the server is unreachable
    } else {
      console.error('Unknown network error:', networkError);
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache,
});

export function refetchAllQueries() {
  client.refetchQueries({
    include: 'all',
  });
}

export default client;
