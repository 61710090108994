import { useState, useEffect } from 'react';
import { Box, Grid, Tab, Tabs, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactSVG } from 'react-svg';
import Orderofplaytable from 'src/components/widgets/tables/orderofplaytable/OrderofplayTable';
import GroupGridView from 'src/components/widgets/tables/groupsTable/GroupGrid';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DOWNLOAD_OOP_GRID_PDF, DOWNLOAD_OOP_LIST_PDF, ORDEROFPLAY, ORDER_OF_PLAY_DATES, PARTICIPANTS, TOURNAMENT_DOCUMENTS } from 'src/graphql/queries';
import moment from 'moment';
import Slider from 'react-slick';
import toast from 'react-hot-toast';
import { Apps, DownloadSharp, FormatListBulleted, GetApp, Reorder, SearchOutlined } from '@material-ui/icons';
import useDebounce from 'src/utils/debounce';
import Loading from 'src/components/Loading';
import gtm from 'src/lib/gtm';
import { useSearchParams } from 'react-router-dom';
import BirdsView from 'src/components/widgets/tables/groupsTable/BirdsView';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';
import { useTranslation } from 'react-i18next';
import CustomSection from '../../../layout/CustomSection';
import CustomButton from '../../../CustomComponents/CustomButton';
import CustomSelect from '../../../CustomComponents/CustomSelect';
import dayjs from 'dayjs';
import { downloadFile } from 'src/utils/helperFunction';
import IOSSwitch from 'src/components/CustomComponents/IOSwitch';
import { DownloadOutlined } from '@material-ui/icons';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import { useTournament } from '../TournamentPage';

const OrderOfPlay = () => {
  const { id, setRefetch } = useTournament();
  const { t } = useTranslation();
  const [filtersParams, setFiltersParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState<string>(filtersParams.get('view') || 'timeLine');
  const [search, setSearch] = useState(filtersParams.get('name') || '');
  const [slickSlider, setSlider] = useState<Slider | null>(null);
  const [date, setDate] = useState<any>('');
  const [isHideMatches, setIsHideMatches] = useState(false);
  const paramsDate = filtersParams.get('date');

  const {
    data: oopDates,
    loading: datesLoading,
    refetch: refetchOOPDates,
  } = useQuery(ORDER_OF_PLAY_DATES, {
    skip: !id,
    variables: {
      hideCompleteMatches: isHideMatches,
      filter: { tournament: id },
    },
    fetchPolicy: 'network-only',
  });
  const { data, loading: documentLoading } = useQuery(TOURNAMENT_DOCUMENTS, {
    skip: !id,
    variables: { filter: { tournament: id, section: 'oop' } },
  });
  const debouncedSearch = useDebouncedTerm(search, 500);
  const debouncedDate = useDebouncedTerm(date, 500);

  const {
    data: oopData,
    loading,
    refetch: refetchOOP,
  } = useQuery(ORDEROFPLAY, {
    skip: !id || !date,
    variables: {
      hideCompleteMatches: isHideMatches,
      viewType: currentTab,
      filter: {
        tournament: id,
      },
      ...(debouncedSearch && { name: debouncedSearch }),
      ...(debouncedDate && { date: moment.utc(debouncedDate).format('YYYY-MM-DD') }),
    },
  });

  const { data: participants, refetch: refetchParticipants } = useQuery(PARTICIPANTS, {
    variables: {
      filter: {
        tournament: id,
        status: 'confirm',
      },
    },
  });

  const participantsFullNames = participants
    ? (Array.from(new Set(participants?.tournamentRegistrations?.flatMap((participant) => participant?.users?.map((user) => user?.user?.surname)))) as string[])
    : [];
  useEffect(() => {
    if (oopDates?.tournamentMatchesDates?.length > 0 && !date) {
      const today = moment();
      const closestDate = oopDates.tournamentMatchesDates.reduce((prev, curr) => {
        return Math.abs(moment(curr).diff(today)) < Math.abs(moment(prev).diff(today)) ? curr : prev;
      });
      const isParamDateIncludes = oopDates.tournamentMatchesDates.some((d) => moment.utc(d).format('YYYY-MM-DD') === moment.utc(paramsDate).format('YYYY-MM-DD'));
      setDate(isParamDateIncludes ? paramsDate : closestDate);
    }
  }, [oopDates, date, paramsDate]);

  const [generatePDF] = useLazyQuery(currentTab === 'grid' ? DOWNLOAD_OOP_GRID_PDF : DOWNLOAD_OOP_LIST_PDF, {
    variables: {
      id: String(id),
    },
  });

  // const [generateDUPRPDF] = useLazyQuery(DOWNLOAD_DUPR_CSV_OOP, {
  //   variables: {
  //     id: String(id),
  //     date: moment(date).format('YYYY-MM-DD'),
  //   },
  // });

  const handleDownload = () => {
    toast.promise(generatePDF(), {
      loading: 'Generating PDF...',
      success: (res) => {
        if (res?.data) {
          const link = document.createElement('a');
          link.href =
            (currentTab === 'grid' ? res?.data?.PdfGenerationForGrid : res?.data?.PdfGeneration) + `?date=${moment(date).format('YYYY-MM-DD')}&name=${search}&hideCompleteMatches=${isHideMatches}`;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return <b>PDF Generated Successfully!</b>;
      },
      error: <b>Failed to generate PDF</b>,
    });
  };

  // const handleDownloadDUPR = () => {
  //   toast.promise(generateDUPRPDF(), {
  //     loading: 'Generating CSV...',
  //     success: (res) => {
  //       if (res?.data) {
  //         const link = document.createElement('a');
  //         link.href = res?.data?.CsvGenerationForPickleballOOP;
  //         link.download = 'file.csv';
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       }
  //       return <b>CSV being generated...</b>;
  //     },
  //     error: <b>Failed to generate CSV</b>,
  //   });
  // };

  useEffect(() => {
    if (!filtersParams.get('date') && !filtersParams.get('name')) {
      setDate(null);
      setSearch('');
    }
  }, [id, filtersParams]);

  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(search ? { name: search } : {}),
        ...(date ? { date: new Date(date).toISOString() } : {}),
        ...(currentTab ? { view: currentTab } : {}),
      };

      // Remove parameters with empty values
      if (!search) {
        delete newParams.name;
      }
      if (!date) {
        delete newParams.date;
      }

      setFiltersParams(newParams);
    },
    [search, date, currentTab],
    100,
  );

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const uniqueOptions = oopDates?.tournamentMatchesDates
    .reduce((acc, date) => {
      const formattedDate = moment.utc(date).format('DD/MM/YYYY');
      if (!acc.some((option) => option.title === formattedDate)) {
        acc.push({ title: formattedDate, value: date });
      }
      return acc;
    }, [])
    .sort((a, b) => (moment(a.value).isBefore(moment(b.value)) ? -1 : 1));

  useEffect(() => {
    const refetchData = () => {
      void refetchOOP();
      // void refetchParticipants();
      void refetchOOPDates();
      toast.success('Order of play data has been successfully refreshed.');
    };

    setRefetch(refetchData);

    return () => setRefetch(undefined);
  }, []);

  // Hide/Show completed matches
  const handleMatchesHide = () => {
    setIsHideMatches(!isHideMatches);
  };

  const filtersEls = (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '8px', flexWrap: { md: 'unset', xs: 'wrap' } }}>
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          gap: '5px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 8px',
          border: '1px solid #dcdee2',
          borderRadius: '8px',
          background: '#ffff',
        }}
      >
        <Typography variant="font18" sx={{ color: '#86909F', fontSize: '12px', fontWeight: isHideMatches ? 600 : 400, lineHeight: '20px' }}>
          Hide completed matches
        </Typography>

        <IOSSwitch checked={isHideMatches} onChange={handleMatchesHide} name="checkedC" />
      </Box>
      {/* <CustomAutocomplete
        options={participantsFullNames.map((participant) => ({ text: participant, value: participant }))}
        placeholder="Enter surname"
        selectedValue={debouncedSearch}
        onChange={(_, value) => setSearch(value.value)}
        className={classes.textfield}
        sx={{ flex: { xs: '1', sm: 'none' }, minWidth: { md: '250px', xs: '150px' } }}
      /> */}
      <CustomFormInput
        placeholder={t('Enter surname')}
        name="Enter surname"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        size="small"
        InputProps={{
          endAdornment: <SearchOutlined sx={{ fontSize: '20px' }} />,
        }}
      />
      <CustomSelect placeholder="Select date" selectedValue={date} options={uniqueOptions} onChange={(value) => setDate(value)} />
      {data?.tournamentDocuments?.length > 0 && (
        <CustomSelect
          onChange={(value) => {
            let data = JSON.parse(value.toString());
            toast.promise(downloadFile(data?.document, data?.name), {
              loading: 'Downloading...',
              success: () => {
                return `File Downloaded Successfully!`;
              },
              error: (err) => {
                return err.message;
              },
            });
          }}
          placeholder="Documents"
          options={data?.tournamentDocuments?.map((item) => ({ value: JSON.stringify(item), title: item?.name, rightIcon: <DownloadSharp sx={{ fontSize: '16px' }} /> }))}
          buttonProps={{
            // size: 'medium',
            sx: {
              width: '100%',
              maxWidth: { sm: '150px', xs: '85%' },
            },
          }}
        />
      )}
      <Box
        sx={{
          width: 'calc(100vw - 78px)',
          display: { xs: 'flex', sm: 'none' },
          gap: '5px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6px 8px',
          border: '1px solid #EDF1F6',
          borderRadius: '8px',
        }}
      >
        <Typography variant="body2" sx={{ color: '#1B3861', fontSize: '14px', fontWeight: isHideMatches ? 600 : 400, lineHeight: '20px' }}>
          Hide completed matches
        </Typography>

        <IOSSwitch checked={isHideMatches} onChange={handleMatchesHide} name="checkedC" />
      </Box>
      {currentTab !== 'birdView' && (
        <Tooltip title="Download PDF">
          <CustomButton
            size="small"
            variant="outline"
            square
            onClick={() => handleDownload()}
            // disabled={!oop?.length}
            // sx={{
            //   color: 'inherit',
            //   border: '1px solid rgba(10, 37, 64, 0.10)',
            //   background: '#FAFAFA',
            //   borderRadius: '6px',
            //   padding: '8px',
            // }}
          >
            <DownloadOutlined sx={{ fontSize: '18px' }} />
          </CustomButton>
        </Tooltip>
      )}
    </Box>
  );

  return (
    <>
      <CustomSection title={t('Order of play')} titleRightSideElement={!isMobile ? filtersEls : null} underTitleElement={isMobile ? filtersEls : null}>
        <Grid container sx={{ mb: 1, borderBottom: '1px solid #EDF1F6' }}>
          <Grid item md={6} xl={4} xs={12}>
            <Tabs
              value={currentTab}
              onChange={(event, newValue) => {
                setCurrentTab(newValue);
              }}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              aria-label="order of play view"
              sx={{
                '& .MuiTab-wrapper': {
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                '& .MuiTab-root': {
                  minWidth: '110px',
                  minHeight: '40px',
                },
                '& .MuiSvgIcon-root': {
                  marginBottom: '0px !important',
                },
              }}
            >
              <Tab icon={<Reorder fontSize="small" />} label={t('Timeline')} value="timeLine" />
              <Tab icon={<Apps fontSize="small" />} label={t('Grid')} value="grid" />
              <Tab icon={<FormatListBulleted fontSize="small" />} label={t("Bird's View")} value="birdView" />
            </Tabs>
          </Grid>
        </Grid>
        {oopData?.tournamentAllMatches?.matchesArray?.length > 0 && currentTab === 'grid' && (
          <Box sx={{ display: 'flex', gap: '5px', justifyContent: 'end', mt: '5px' }}>
            <ReactSVG src="/images/arr-left.svg" style={{ cursor: 'pointer' }} onClick={() => slickSlider?.slickPrev()} />
            <ReactSVG src="/images/arr-right.svg" style={{ cursor: 'pointer' }} onClick={() => slickSlider?.slickNext()} />
          </Box>
        )}

        {loading || datesLoading ? (
          <Loading height="50vh" />
        ) : currentTab == 'timeLine' ? (
          <Orderofplaytable oopData={oopData?.tournamentAllMatches?.matchesArray} show={oopData?.tournamentAllMatches?.showOOP} tournamentDocuments={data?.tournamentDocuments} />
        ) : currentTab == 'grid' ? (
          <GroupGridView
            setCurrentTab={setCurrentTab}
            oopData={oopData?.tournamentAllMatches?.matchesArray}
            setSlider={setSlider}
            slickSlider={slickSlider}
            show={oopData?.tournamentAllMatches?.showOOP}
            tournamentDocuments={data?.tournamentDocuments}
          />
        ) : (
          <BirdsView show={oopData?.tournamentAllMatches?.showOOP} oopData={oopData?.tournamentAllMatches?.matchesArray} tournamentDocuments={data?.tournamentDocuments} />
        )}
      </CustomSection>
    </>
  );
};

export default OrderOfPlay;
