import { gql } from '@apollo/client';

const REMOVE_CLUB = gql`
  mutation removeClubMember($id: Int!) {
    removeClubMember(id: $id) {
      id
    }
  }
`;
export default REMOVE_CLUB;
