import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import './assets/fonts/montserrat/stylesheet.css';
import './assets/fonts/roboto/stylesheet.css';
import './assets/fonts/eudoxus-sans/stylesheet.css';
import './assets/fonts/Inter/stylesheet.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import Cookies from 'js-cookie';
import Loading from './components/Loading';
import { eventEmitter, getPlatformId } from './constants';
import { Helmet } from 'react-helmet-async';
import moment from 'moment-timezone';
import '@tabler/icons-webfont/dist/tabler-icons.css';

moment.tz.setDefault('UTC');

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const tokenParam = queryParams.get('token');
  const openLoginModal = queryParams.get('openLogin');
  const openRegModal = queryParams.get('openRegister');
  const openForgetModal = queryParams.get('openReset');

  const isMaintenanceEnabled = auth?.platformData?.isEnableMaintenance;
  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;
  // const maintenanceCode = process.env.REACT_APP_MAINTENANCE_CODE;
  const maintenanceCode = auth?.platformData?.passcode;

  useEffect(() => {
    const handleServerError = () => auth?.setServerCrashModalOpen(true);
    eventEmitter.on('serverError', handleServerError);
    return () => {
      eventEmitter.off('serverError', handleServerError);
    };
  }, [auth]);

  useEffect(() => {
    if (tokenParam) {
      Cookies.set('token', tokenParam);
    }
  }, [tokenParam]);

  const theme = useMemo(
    () =>
      createCustomTheme(
        {
          direction: settings.direction,
          responsiveFontSizes: settings.responsiveFontSizes,
          roundedCorners: settings.roundedCorners,
          theme: settings.theme,
        },
        auth.sidebarOpen ? 400 : 0,
      ),
    [auth.sidebarOpen, settings],
  );

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

    if (!auth?.isAuthenticated) {
      if (openLoginModal) {
        auth?.setLoginModalOpen(true);
      }
      if (openRegModal) {
        auth?.setSignupModalOpen(true);
      }
      if (openForgetModal) {
        auth?.setResetModalOpen(true);
      }
    }

    // if (!openForgetModal && !openLoginModal && !openRegModal && !hasVisitedBefore) {
    //   auth?.setWelcomeModalOpen(true);
    // }
  }, [openForgetModal, openLoginModal, openRegModal, location.pathname, auth?.isAuthenticated]);

  useEffect(() => {
    const decodedMCode = (() => {
      try {
        const mCode = Cookies.get('mCode');
        return mCode ? window.atob(mCode) : null;
      } catch (error) {
        console.error('Error decoding mCode:', error);
        return null;
      }
    })();

    if (isMaintenanceEnabled && (!decodedMCode || decodedMCode !== maintenanceCode)) {
      navigate('/maintenance-code');
    }
  }, [isMaintenanceEnabled, maintenanceCode, navigate]);
  useEffect(() => {
    if (location.pathname.startsWith('/pricing') && auth?.platformData && auth?.platformData?.isEnablePrice !== true) {
      navigate('/');
    }
  }, [location.pathname, auth?.platformData?.isEnablePrice]);
  const isLoading = auth?.platformLoading || !auth.isInitialized;
  const FAVICON = '/images/favicon_ranks.jpeg';
  return (
    <>
      <CssBaseline />
      {isLoading ? (
        <Loading />
      ) : (
        <ThemeProvider theme={theme}>
          <Helmet>
            {auth?.platformData?.title && <title>{auth?.platformData?.title}</title>}
            <meta name="theme-color" content={primaryColor} />
            <link rel="icon" href={getPlatformId() == 14 ? FAVICON : auth?.platformData?.lightLogo || process.env.REACT_APP_LOGO_ICON} />
          </Helmet>
          <RTL direction={settings.direction}>
            <Toaster position="top-center" />
            {content}
          </RTL>
        </ThemeProvider>
      )}
    </>
  );
};

export default App;
