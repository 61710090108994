import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import useLocalizedEvent, { LocalizedEvent } from 'src/utils/useLocalizedEvent';
import { useQuery } from '@apollo/client';
import GET_TOURNAMENT_DETAILS from '../../../graphql/queries/getTournamentDetails';
import useAuth from 'src/hooks/useAuth';
import { useParams } from 'react-router';
import { TournamentDetailByIdQuery } from '../../../__generated__/graphql';
import TournamentPageContent from '../../../pages/dashboard/TournamentPageContent';

export type LocalizedTournament = LocalizedEvent<TournamentDetailByIdQuery['tournamentDetailById'], 'endDate' | 'startDate' | 'withdrawlDeadline' | 'entryDeadline'>;

export const TournamentContext = createContext<{
  id: number;
  loading: boolean;
  tournament: LocalizedTournament;
  refetch: () => void;
  setRefetch: (fn: (() => void) | undefined) => void;
  timeLeft: number;
}>(undefined);

export const useTournament = () => {
  const value = useContext(TournamentContext);

  if (!value) {
    throw Error('This hook needs to be used within TimerProvider');
  }

  return value;
};
export const TournamentProvider = ({ children }) => {
  const auth = useAuth();
  const { id } = useParams();
  const tournamentId = auth?.tournamentId ?? parseInt(id);
  const {
    data,
    loading,
    refetch: refetchTournament,
  } = useQuery(GET_TOURNAMENT_DETAILS, {
    skip: !tournamentId,
    variables: {
      id: tournamentId,
      userId: parseInt(auth?.user?.id),
    },
    fetchPolicy: 'no-cache',
  });

  const tournament = useLocalizedEvent(data?.tournamentDetailById, ['endDate', 'startDate', 'withdrawlDeadline', 'entryDeadline', 'registrationStartDate']);

  const [timeLeft, setTimeLeft] = useState(30);
  const [refetch, setRefetch] = useState<(() => void) | undefined>();

  const updateRefetch = useCallback((fn: (() => void) | undefined) => {
    setRefetch(fn ? () => fn : undefined);
  }, []);

  useEffect(() => {
    if (!tournament?.isOngoing) return;

    if (!refetch) {
      setTimeLeft(30);
      return;
    }

    const handleInterval = () => {
      setTimeLeft((curr) => {
        if (curr > 0) return --curr;
        else {
          refetch();
          return 30;
        }
      });
    };

    const intervalId = setInterval(handleInterval, 1000);
    return () => clearInterval(intervalId);
  }, [tournament?.isOngoing, refetch]);

  return (
    <TournamentContext.Provider
      value={{
        id: tournamentId,
        loading,
        tournament,
        refetch: refetchTournament,
        setRefetch: updateRefetch,
        timeLeft,
      }}
    >
      {children}
    </TournamentContext.Provider>
  );
};

const TournamentPage = () => {
  return <TournamentPageContent />;
};

export default TournamentPage;
