import { gql } from '@apollo/client';

const USER_ENTRIES = gql`
  query tournamentTeamMembersWithCount(
    $filter: ListTournamentTeamMembersInput
    $page: Int
    $limit: Int
    $startCreatedAt: String
    $endCreatedAt: String
    $platform: Int
    $statusArray: [String!]
    $isUpcomingAndProgress: Boolean
  ) {
    tournamentTeamMembersWithCount(
      filter: $filter
      page: $page
      limit: $limit
      startCreatedAt: $startCreatedAt
      endCreatedAt: $endCreatedAt
      platform: $platform
      statusArray: $statusArray
      isUpcomingAndProgress: $isUpcomingAndProgress
    ) {
      tournamentTeamMembers {
        id
        status
        isDelete
        createdAt
        registrationRequest {
          payForMyself
          id
          status
          type
          additionalFee
          createdAt
          paymentStatus
          users {
            id
            user {
              id
              name
              surname
              avatar
            }
          }
        }
        user {
          id
          name
          surname
          avatar
        }
        tournament {
          id
          title
          withdrawlDeadline
          logo
          coverPhoto
          startDate
          country
          additionalQuestion {
            id
            question
          }
        }
        categoryId {
          id
          onsiteFee
          currency
          fee
          onlinePayments
          category {
            id
            name
            type
          }
        }
      }
      total
    }
  }
`;

export default USER_ENTRIES;
