import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import type { ChangeEvent, Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import Scrollbar from 'src/components/Scrollbar';
import UserNameCell from '../pairingsStandings/pairingsComponents/UserNameCell';
import Loading from 'src/components/Loading';
import { PairingStanding } from 'src/types/pairings';
import { ReactComponent as Knight } from 'src/assets/images/knight.svg';
import { ReactComponent as Search } from 'src/assets/images/search-new.svg';
import CustomDropDown from 'src/components/shared/CustomDropDown';
import ResponsiveFiltersPopover from 'src/components/shared/ResponsiveFiltersPopover';
import NoDataFound from '../NoDataFound';
import { getRating } from 'src/utils/helperFunction';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';

interface EntrieListTableProps {
  entriesListData?: PairingStanding[];
  setType: Dispatch<SetStateAction<string>>;
  type: string;
  allTournamentCategories: any;
  categoryID: number;
  setCategoryID: Dispatch<SetStateAction<number>>;
  rounds: { id: number; title: string }[];
  round: number;
  setRound: Dispatch<SetStateAction<number>>;
  timeControl: string;
  setTimeControl: Dispatch<SetStateAction<string>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  isHide?: boolean;
}

type Sort = 'all' | 'updatedAt|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: 'All',
    value: 'all',
  },
];

const useStyles = makeStyles((theme: any) => ({
  topBarButton: {
    height: '42px',
    mt: -0.3,
    backgroundColor: '#FFFF',
    color: '#425466',
    border: `1px solid #EDF1F6`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFF',
    },
  },
  dropDown: {
    display: 'flex',
    borderRadius: '6px',
    border: '1px solid #EDF1F6',
    alignItems: 'center',
    paddingLeft: '8px',
    height: '42px',
    width: '250px',
    backgroundColor: '#FFFF',
  },
  topBarDropdown: {
    // backgroundColor: "#FFFF",
    // height: "42px",
    color: '#425466',
    '& .MuiSelect-select': {
      padding: '5px 14px 5px 5px',
    },
    '& .MuiNativeSelect-select': {
      padding: '0 14px',
      backgroundColor: '#FFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  moreIcon: {
    color: '#425466',
  },
  pts: {
    width: 'max-content',
    fontWeight: 500,
    padding: '3px 5px',
    background: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    color: '#7B7B7B',
  },
  body1: {
    width: 'max-content',
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    display: 'flex !important',
    alignItems: 'center',
  },

  body1Head: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'pre',
    fontSize: '12px',
  },
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: '23.35px',
  },
}));

const applyFilters = (entriesListData: PairingStanding[], query: string, filters: any): PairingStanding[] =>
  entriesListData?.filter((entries) => {
    let matches = true;

    if (query) {
      const searchWords = query.toLowerCase().split(' ');
      const properties = ['name', 'surname'];
      let containsQuery = false;

      properties?.forEach((property) => {
        if (searchWords.every((word) => entries?.users?.some((user) => user?.user[property]?.toLowerCase().includes(word)))) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && entries[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (customers: PairingStanding[], page: number, limit: number): PairingStanding[] => customers?.slice(page * limit, page * limit + limit);

const descendingComparator = (a: PairingStanding, b: PairingStanding, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) =>
  order === 'desc' ? (a: PairingStanding, b: PairingStanding) => descendingComparator(a, b, orderBy) : (a: PairingStanding, b: PairingStanding) => -descendingComparator(a, b, orderBy);

const applySort = (customers: PairingStanding[], sort: Sort): PairingStanding[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis?.map((el) => el[0]);
};

const PairingsListTable: FC<EntrieListTableProps> = (props) => {
  const { entriesListData, setType, type, allTournamentCategories, categoryID, setCategoryID, setRound, round, rounds, loading, setLoading, timeControl, setTimeControl, ...other } = props;
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [query, setQuery] = useState<string>('');
  const [piece, setPiece] = useState(0);
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setQuery('');
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newLimit = parseInt(event.target.value, 10);

    if (newLimit === -1) {
      setLimit(filteredEntries.length);
    } else {
      setLimit(newLimit);
    }
    setPage(0);
    setQuery('');
  };
  const filteredEntries = applyFilters(entriesListData, query, filters);
  const sortedEntries = applySort(filteredEntries, sort);
  const paginatedEntries = applyPagination(sortedEntries, page, limit);

  const classes = useStyles();

  if (loading) {
    return <Loading height="50vh" />;
  }

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="heading17" className={classes.title}>
            {t('Pairing Standings')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: { xs: 1, md: 0 } }}>
          <CustomSelect
            placeholder={t('Category: ')}
            selectedValue={categoryID}
            buttonProps={{
              size: 'small',
              sx: { width: { xs: '45%', md: 'max-content' } },
            }}
            options={
              allTournamentCategories.map((category) => ({
                title: category.category.category.name + ' ' + `(${category.count})`,
                value: category.category.id,
              })) || []
            }
            onChange={(e) => {
              setCategoryID(e);
              const findSelectedCategory = allTournamentCategories.find((category) => category.category.id === e);
              if (findSelectedCategory) {
                setType(findSelectedCategory.category.category.type);
                setTimeControl(findSelectedCategory.category.category.timeControl);
              }
            }}
          />

          <CustomSelect
            placeholder={t('Round: ')}
            selectedValue={round}
            buttonProps={{
              size: 'small',
              sx: { width: { xs: '40%', md: 'max-content' } },
            }}
            options={rounds.map((round) => ({ title: round.title, value: round.id })) || []}
            onChange={(e) => {
              setRound(e);
            }}
          />

          <ResponsiveFiltersPopover position="space-between" startElements={1} sx={{ width: { xs: '10%', md: 'max-content' } }}>
            <CustomFormInput
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <ReactSVG
                      src="/images/search-new.svg"
                      beforeInjection={(svg) => {
                        svg.setAttribute('style', 'margin-top: 5px;');
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder={t('Search')}
              value={query}
            />
          </ResponsiveFiltersPopover>
        </Grid>
      </Grid>
      {entriesListData?.length > 0 ? (
        <Card
          {...other}
          sx={{
            boxShadow: 'none',
            border: '1px solid #EDF1F6',
            borderRadius: '6px',
          }}
        >
          <Box
            sx={{
              padding: '12px',
              height: 'calc(100vh - 230px)',
              overflow: 'auto',
            }}
          >
            <Table>
              <TableHead
                sx={{
                  position: 'sticky',
                  top: -15,
                  background: '#fff',
                  zIndex: 1,
                  borderBottom: '1px solid #EDF1F6',
                }}
              >
                <TableRow>
                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('NR')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('Name')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('Rating')}
                    </Typography>
                  </TableCell>

                  {/* <TableCell>
                      <Typography
                        // variant="body1"
                        className={classes.body1Head}
                      >
                        Clubs/City
                      </Typography>
                    </TableCell> */}

                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('Pts')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('Opponents')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('BHC1')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="body1"
                      className={classes.body1Head}
                    >
                      {t('BHC')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      //  variant="body1"
                      className={classes.body1Head}
                    >
                      {t('BGP')}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      //  variant="body1"
                      className={classes.body1Head}
                    >
                      {t('WIN')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedEntries?.map((item, index) => {
                  const sortingNumber = page * limit + index + 1;
                  return (
                    <TableRow hover key={item.id} className="entry-row">
                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {sortingNumber}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <UserNameCell user={item?.users?.[0]?.user} fideTitle={item?.users?.[0]?.fideTitle} />
                      </TableCell>
                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {getRating(item?.users?.[0], timeControl)}
                        </Typography>
                      </TableCell>

                      {/* <TableCell>
                          <Typography
                            // variant="body1"
                            className={classes.body1}
                          >
                            {item?.users?.[0]?.user?.city}
                          </Typography>
                        </TableCell> */}

                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {item?.pts}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <AvatarGroup
                          max={4}
                          sx={{
                            justifyContent: 'start',
                            '& .MuiAvatarGroup-avatar': {
                              width: '24px',
                              height: '24px',
                              fontSize: '.6rem',
                              cursor: 'pointer',
                            },
                          }}
                        >
                          {item?.opponents?.map((opponent) => {
                            return (
                              <Tooltip title={`${opponent?.users?.[0]?.user?.name} ${opponent?.users?.[0]?.user?.surname}`} placement="top">
                                <Avatar key={opponent?.id} alt={opponent?.users?.[0]?.user?.name} src={opponent?.users?.[0]?.user?.avatar} />
                              </Tooltip>
                            );
                          })}
                        </AvatarGroup>
                      </TableCell>

                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {item?.bhc1}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {item?.bh}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {item?.bgp}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography
                          // variant="body1"
                          className={classes.body1}
                        >
                          {item?.wins}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              mt: '10px',
            }}
          >
            <Box
              sx={{
                marginLeft: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                '@media (max-width: 525px)': {
                  mt: '10px',
                },
              }}
            ></Box>
            <TablePagination
              sx={{
                '.MuiToolbar-gutters': { paddingRight: '0px !important', paddingLeft: '0px !important' },
              }}
              component="div"
              count={filteredEntries.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 100, { label: 'Show All', value: filteredEntries.length }]}
            />
          </Box>
        </Card>
      ) : (
        <Box sx={{ mt: 2 }}>
          <NoDataFound text={t('There is no available entries!')} sx={{ margin: '50px 0' }} />
        </Box>
      )}
    </React.Fragment>
  );
};

PairingsListTable.propTypes = {
  entriesListData: PropTypes.array.isRequired,
};

export default PairingsListTable;
