import { Box, Typography } from '@material-ui/core';
import { DownloadSharp } from '@material-ui/icons';
import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import { downloadFile } from 'src/utils/helperFunction';

const DownloadOOP = ({ tournamentDocuments }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '50vh', gap: '10px' }}>
      <Typography variant="font17" sx={{ textAlign: 'center' }}>
        {t('Download the order of play')}
      </Typography>
      <CustomSelect
        onChange={(value) => {
          let data = JSON.parse(value.toString());
          toast.promise(downloadFile(data?.document, data?.name), {
            loading: 'Downloading...',
            success: () => {
              return `File Downloaded Successfully!`;
            },
            error: (err) => {
              return err.message;
            },
          });
        }}
        placeholder="Documents"
        options={tournamentDocuments?.map((item) => ({ value: JSON.stringify(item), title: item?.name, rightIcon: <DownloadSharp sx={{ fontSize: '16px' }} /> }))}
        buttonProps={{
          size: 'medium',
          sx: {
            width: '100%',
            maxWidth: { sm: '150px', xs: '85%' },
          },
        }}
      />
    </Box>
  );
};

export default DownloadOOP;
