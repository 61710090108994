import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import React from 'react';

interface PopupWarningProps {
  openWarningModal: boolean;
  handleClose: () => void;
  handleAction: () => void;
  warningText: string | React.ReactNode;
  warningTitle?: string;
  confirmText?: string;
  hideConfirm?: boolean;
  cancelText?: string;
  confirmColor?: string;
}

const DeleteWarning = ({ openWarningModal, handleClose, handleAction, warningText, warningTitle, confirmText, confirmColor, hideConfirm = false, cancelText }: PopupWarningProps) => {
  return (
    <Dialog open={openWarningModal} onClose={handleClose}>
      <DialogTitle sx={{ '& .MuiTypography-root': { display: 'flex', alignItems: 'center', gap: '10px' } }}>
        <Warning color="action" sx={{ mt: -0.2 }} />
        {warningTitle ? warningTitle : 'Confirm Deletion'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{warningText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelText || 'Cancel'}
        </Button>
        {!hideConfirm && (
          <Button onClick={handleAction} sx={{ color: confirmColor || 'red' }}>
            {confirmText ? confirmText : 'Delete'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWarning;
