import { ReactElement, useContext, useEffect, useState } from 'react';
import CustomModal from '../../';
import LoginContent from './LoginContent';
import SignupContent from './SignupContent';
import AuthContext from 'src/contexts/JWTContext';
import ForgetContent from './ForgetContent';
import { useTranslation } from 'react-i18next';
import ResetContent from './ResetContent';
import useAuth from 'src/hooks/useAuth';
import { useLocation, useNavigate } from 'react-router';
import { Box } from '@material-ui/core';

export type Modals = 'login' | 'register' | 'reset' | 'forget';

type Props = {
  children?: ReactElement;
  modal: Modals;
  openOverride: boolean;
};

const AuthModal = ({ children, modal, openOverride }: Props) => {
  const [currentModal, setCurrentModal] = useState(modal);
  const [isOpen, setIsOpen] = useState(false);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const { t } = useTranslation();

  const { user } = useAuth();

  const location = useLocation();

  const navigate = useNavigate();

  const isRestrictedPath = location.pathname.startsWith('/userSettings') || location.pathname === '/mycareer' || location.pathname === '/get-licence' || location.pathname === '/my-clubs';

  const { setSignupModalOpen, setForgetModalOpen, setLoginModalOpen, setResetModalOpen } = useContext(AuthContext);

  const handleClose = () => {
    setSignupModalOpen(false);
    setForgetModalOpen(false);
    setLoginModalOpen(false);
    setResetModalOpen(false);

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('openLogin') || searchParams.has('openReset')) {
      searchParams.delete('openLogin');
      searchParams.delete('openReset');
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  useEffect(() => {
    if (user) handleClose();
    if (!user && isRestrictedPath) {
      //close modal
      navigate('/');
    }
  }, [user, isRestrictedPath]);

  useEffect(() => {
    if (isOpen) setCurrentModal(modal);
  }, [isOpen]);

  return (
    <CustomModal
      openOvveride={openOverride}
      triggerEl={children}
      title={currentModal === 'login' ? t('Log in') : currentModal === 'register' ? t('Create an account') : t('Reset your password')}
      handleBack={showBackBtn}
      onClose={handleClose}
    >
      {(handleClose, open, backButtonClickCounter, setBackButtonClickCounter) => {
        setIsOpen(open);

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '8px',
              maxWidth: { xs: 'none', sm: '400px' },
              paddingTop: '12px',
              minWidth: { xs: '100%', sm: '400px' },
              minHeight: { xs: '65dvh', sm: 'auto' },
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {currentModal === 'login' && <LoginContent handleClose={handleClose} setModal={setCurrentModal} />}
            {currentModal === 'register' && (
              <SignupContent
                handleClose={handleClose}
                setModal={setCurrentModal}
                backButtonClickCounter={backButtonClickCounter}
                setShowBackBtn={setShowBackBtn}
                setBackButtonClickCounter={setBackButtonClickCounter}
              />
            )}
            {currentModal === 'forget' && (
              <ForgetContent
                handleClose={handleClose}
                setModal={setCurrentModal}
                backButtonClickCounter={backButtonClickCounter}
                setShowBackBtn={setShowBackBtn}
                setBackButtonClickCounter={setBackButtonClickCounter}
              />
            )}
            {currentModal === 'reset' && <ResetContent handleClose={handleClose} />}
          </Box>
        );
      }}
    </CustomModal>
  );
};

export default AuthModal;
