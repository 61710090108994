import { gql } from '@apollo/client';

const GETUSERBYID = gql`
  query getUserById($id: Float, $email: String) {
    user(query: { id: $id, email: $email }) {
      email
      # accessToken
      id
      name
      surname
      role
      friends {
        id
        name
        surname
        avatar
        email
        nation
        citizenship
      }
      user_sports {
        id
        sport {
          id
          title
        }
      }
      gender
      dob
      nation
      avatar
      phone
      street
      city
      facebook
      instagram
      website
      gtw_points
      preferred_language
      user_coach {
        id
        user {
          id
        }
        coaches {
          id
          user {
            id
            email
          }
        }
      }
      user_parents {
        id
        parent {
          id
          name
          email
        }
      }
      user_club_id {
        id
        clubName
      }
      coach_access
      club_access
      parent_access
      createdAt
      linkedIn
      youtube
      tiktok
      citizenship
      user_address
      fideId
      ltsU10Id
      duprId
      pdlId
      ipinId
      teId
      atpId
      wtaId
      additionalUserRole
      plans {
        id
        plan
      }
      stripe_payments {
        productId
        status
      }
    }
  }
`;

export default GETUSERBYID;
