import { gql } from '@apollo/client';

const ADD_CLUB = gql`
  mutation createClubMember($createClubMemberInput: CreateClubMemberInput!) {
    createClubMember(createClubMemberInput: $createClubMemberInput) {
      id
    }
  }
`;
export default ADD_CLUB;
