import { Dispatch, FC, SetStateAction, Fragment, useState, useEffect } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Divider, Modal, Paper, Stack, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Scrollbar from 'src/components/Scrollbar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import useAuth from 'src/hooks/useAuth';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { USER_ROLES } from 'src/constants';
import { CREATE_PARTNER_SEARCH, UserValidations } from 'src/graphql/mutations';
import useDebounce from 'src/utils/debounce';
import ALLATHLETES from 'src/graphql/queries/getAllAthletes';
import CustomModal from '../../../CustomComponents/CustomModal';
import CustomSelect from '../../../CustomComponents/CustomSelect';
import CustomFormInput from '../../../CustomComponents/CustomFormInput';
import CustomButton from '../../../CustomComponents/CustomButton';
import { USER_PARTNER_SEARCH_CATEGORIES, USER_TOURNAMENT_CATEGORIES } from 'src/graphql/queries';

interface ModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  categories: any;
  category: string;
}

const CreateApplicationModal: FC<ModalProps> = ({ openModal, setOpenModal, categories, category }) => {
  const yupSchema = Yup.object().shape({
    category: Yup.string().required('Category is Required Field'),
    player: Yup.object().required('Player is required').nullable(),
    // rank: Yup.string().required("Rank is Required Field"),
    // partnerInfo: Yup.string().max(255).required("Details is Required Field"),
    // selfInformation: Yup.string().max(255).required("Partner Details is Required Field"),
  });

  const { user } = useAuth();
  const [options, setOptions] = useState([]);
  const [name, setName] = useState('');
  const { id } = useParams();
  const {
    loading: userCatLoading,
    data: userCat,
    refetch,
  } = useQuery(USER_PARTNER_SEARCH_CATEGORIES, {
    skip: !id,
    fetchPolicy: 'network-only',
    variables: {
      email: user?.email,
      filter: { tournament: parseInt(id) },
    },
  });
  const allowedRoles = [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.ORGANIZER];

  const [checkUserValid, { loading }] = useMutation(UserValidations);
  const [createPartnerSearch] = useMutation(CREATE_PARTNER_SEARCH, {
    refetchQueries: ['userPartnerSearches', 'partnerSearches', 'userTournamentCategories'],
  });

  const [getUsers, { data, loading: usersLoading }] = useLazyQuery(ALLATHLETES);

  useDebounce(
    async () => {
      // if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      //   return;
      // }
      if (!name) {
        return;
      }
      try {
        const res = await getUsers({
          variables: {
            page: 1,
            limit: 10,
            filter: {
              keyword: name,
            },
          },
        });

        setOptions(res?.data?.users?.users || []);
      } catch (err) {
        setOptions([]);
        toast.error(err?.message);
      }
    },
    [name],
    500,
  );

  useEffect(() => {
    // if (allowedRoles.includes(user?.role)) {
    setOptions([{ id: user?.id, name: user?.name, email: user?.email, surname: user?.surname }]);
    // }
  }, [openModal]);
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Fragment>
      <CustomModal openOvveride={openModal} onClose={handleClose} title="Create Application">
        <Formik
          enableReinitialize
          initialValues={{
            category: category || '',
            player: user || null,
            // rank: '',
            partnerInfo: '',
            selfInformation: '',
          }}
          validationSchema={yupSchema}
          onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
            try {
              const variables = {
                tournament: parseInt(id),
                user: values.player?.id,
                categoryId: parseInt(values.category),
                selfInformation: values.selfInformation,
                partnerInformation: values.partnerInfo,
                // rank: parseInt(values.rank),
                userEmail: values.player?.email,
              };

              await checkUserValid({
                variables: {
                  email: values.player?.email,
                  tournament: parseInt(id),
                  category: parseInt(values.category),
                },
              });

              await createPartnerSearch({
                variables,
              });

              handleClose();
              resetForm();
              toast.success(`Application submitted successfully`);
            } catch (err) {
              toast.error(err?.message);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldTouched, setFieldValue, touched, values }): JSX.Element => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Stack sx={{ mt: '10px', minWidth: '350px', gap: '12px' }}>
                <CustomSelect
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && (errors.category as any)}
                  placeholder="Select Category"
                  onChange={(value) => {
                    setFieldValue('category', value);
                  }}
                  selectedValue={values.category}
                  buttonProps={{
                    size: 'medium',
                  }}
                  options={userCat?.userPartnerSearchCategories
                    ?.filter((item) => item?.category?.type === 'doubles')
                    ?.map((item) => ({ title: item?.category?.name, value: item?.id, disabled: item?.isRegistered }))}
                />
                <Autocomplete
                  fullWidth
                  disabled={!allowedRoles.includes(user?.role) ? true : false}
                  disableClearable
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  clearOnBlur={false}
                  value={values.player}
                  options={options}
                  loading={usersLoading}
                  getOptionLabel={(option) => `${option?.name} ${option?.surname} - ${option?.email}`}
                  onChange={(event: React.ChangeEvent<{}>, value: any, reason: any, option) => {
                    setFieldValue('player', value);
                  }}
                  renderInput={(params) => (
                    <CustomFormInput
                      fullWidth
                      name="player"
                      error={Boolean(touched.player && errors.player)}
                      helperText={touched.player && (errors.player as any)}
                      placeholder="Enter name"
                      variant="outlined"
                      {...params}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {usersLoading ? <CircularProgress sx={{ color: '#FF5733' }} size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                {/* <CustomFormInput
                  error={Boolean(touched?.rank && errors.rank)}
                  fullWidth
                  helperText={touched?.rank && (errors.rank as any)}
                  placeholder="Rank"
                  name="rank"
                  onChange={handleChange}
                  value={values.rank}
                  variant="outlined"
                  type="number"
                /> */}
                <CustomFormInput
                  error={Boolean(touched.selfInformation && errors.selfInformation)}
                  fullWidth
                  helperText={touched.selfInformation && (errors.selfInformation as any)}
                  placeholder="Share information about yourself, your preferences, and contact details"
                  name="selfInformation"
                  onChange={handleChange}
                  value={values.selfInformation}
                  multiline
                  rows={4}
                  sx={{ height: 'fit-content', paddingBlock: '4px' }}
                />
                <CustomFormInput
                  error={Boolean(touched.partnerInfo && errors.partnerInfo)}
                  fullWidth
                  helperText={touched.partnerInfo && (errors.partnerInfo as any)}
                  placeholder="What kind of partner you are looking for?"
                  name="partnerInfo"
                  onChange={handleChange}
                  value={values.partnerInfo}
                  multiline
                  rows={4}
                  sx={{ height: 'fit-content', paddingBlock: '4px' }}
                />
              </Stack>
              <CustomButton variant="primary" size="large" type="submit" disabled={isSubmitting} sx={{ width: '100%', mt: '12px' }}>
                Submit
              </CustomButton>
            </form>
          )}
        </Formik>
      </CustomModal>
    </Fragment>
  );
};

export default CreateApplicationModal;
