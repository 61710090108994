import React, { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import TournamentHeader from 'src/components/dashboard/TournamentPage/TournamentHeader';
import General from 'src/components/dashboard/TournamentPage/general/General';
import Participants from 'src/components/dashboard/TournamentPage/participants/Participants';
import Groups from 'src/components/dashboard/TournamentPage/groups/Groups';
import Draws from 'src/components/dashboard/TournamentPage/draws/Draws';
import LiveStandings from 'src/components/dashboard/TournamentPage/liveStandings/LiveStandings';
import OrderOfPlay from 'src/components/dashboard/TournamentPage/orderofplay/OrderOfPlay';
import PartnerSearch from 'src/components/dashboard/TournamentPage/partnerSearch/PartnerSearch';
import ListAndResults from 'src/components/dashboard/TournamentPage/listandResults/ListAndResults';
import { tabs } from 'src/constants';
import Rankings from 'src/components/dashboard/TournamentPage/points/TournamentResults';
import Pairings from './Pairings';
import PairingsStandings from './PairingsStandings';
import { parseToJSON } from 'src/utils/helperFunction';
import { useSearchParams } from 'react-router-dom';
import ScheduleForm from 'src/components/dashboard/TournamentPage/eventSchedule/EventSchedule';
import BottomBar from 'src/components/dashboard/TournamentPage/BottomBar';
import useScrollToTop from '../../utils/useScrollToTop';
import { useTournament } from '../../components/dashboard/TournamentPage/TournamentPage';
import useAuth from '../../hooks/useAuth';
import TournamentGallery from 'src/components/dashboard/generalGallery';
const MemoizedScheduleForm = memo(ScheduleForm);

const TournamentPageContent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>(searchParams.get('tab') || 'general');
  const [open, setOpen] = useState(false);
  const { loading, tournament } = useTournament();
  const auth = useAuth();

  const handleTabsChange = (value: string): void => {
    setSearchParams({ ...(value && { tab: value }) });
  };

  useEffect(() => {
    if (loading) return;

    const validTabs = filterTabs().map((tab) => tab.value);
    const currentTab = searchParams.get('tab');

    if (!currentTab || !validTabs.includes(currentTab)) {
      searchParams.set('tab', 'general');
      setSearchParams(searchParams);
      setCurrentTab('general');
    } else {
      setCurrentTab(currentTab);
    }
  }, [searchParams, tournament, loading]);

  useScrollToTop({ deps: [currentTab] });

  const filterTabs = (): typeof tabs => {
    const { league } = tournament || {};
    const { sports: settings } = league?.league || {};
    const { groups, draws, liveStandings, tournamentRankings, pairings, showOOP, partnerSearch, events, Album, isHideEntries } = tournament || {};

    const tabConditions = new Map<string, boolean>([
      ['participants', !isHideEntries],
      ['groups', !!settings?.displayGroups && groups?.length > 0],
      ['draws', !!settings?.displayDraws && draws?.length > 0],
      ['livestandings', !!settings?.displayLiveStandings && liveStandings?.length > 0],
      ['results', !!settings?.displayListResults],
      ['points', !!settings?.displayTournamentResults && tournamentRankings?.length > 0],
      ['order_of_play', !!settings?.displayOrderPlay && showOOP],
      ['pairings', !!settings?.isEnablePairings && pairings?.length > 0],
      ['pairings-standings', !!settings?.isEnablePairingStandings && pairings?.length > 0 && pairings?.some((pairing) => pairing?.hideStandings === false)],
      ['search', !!partnerSearch],
      ['events', !!events],
      ['gallery', Album?.length > 0],
    ]);

    return tabs.filter((tab) => tabConditions.get(tab.value) ?? true);
  };

  const chessTimeControl = tournament?.tournamentCategory?.reduce((unique, item) => {
    const timeControl = parseToJSON(item?.category?.timeControl) ?? {};
    if (!unique.some((control) => JSON.stringify(control) === JSON.stringify(timeControl))) {
      if (Object.keys(timeControl).length > 0) {
        unique.push(timeControl);
      }
    }
    return unique;
  }, []);

  return (
    <>
      <Helmet>
        <title>{tournament?.title ?? 'Tournament'} | General</title>
      </Helmet>
      <TournamentHeader open={open} setOpen={setOpen} tabsConfig={{ onChange: handleTabsChange, hideTabs: loading, tabs: filterTabs(), currentTab }} />
      <Container
        maxWidth={false}
        sx={{
          maxWidth: '1920px',
        }}
      >
        <Box sx={{ marginBlock: '15px' }}>
          {(() => {
            const tabComponents: Record<string, JSX.Element> = {
              general: <General />,
              events: <MemoizedScheduleForm />,
              participants: <Participants />,
              search: <PartnerSearch />,
              groups: <Groups />,
              draws: <Draws />,
              livestandings: <LiveStandings />,
              order_of_play: <OrderOfPlay />,
              points: <Rankings />,
              results: <ListAndResults />,
              pairings: <Pairings />,
              'pairings-standings': <PairingsStandings />,
              gallery: <TournamentGallery />,
            };

            return tabComponents[currentTab] || null;
          })()}
        </Box>
      </Container>
      {auth?.user && <BottomBar />}
    </>
  );
};

export default TournamentPageContent;
