import CustomPaperCard from '../../../CustomComponents/CustomPaperCard';
import { Box, Stack, Typography } from '@material-ui/core';
import React from 'react';
import { useQuery } from '@apollo/client';
import { GETENTRIESCOUNT, PARTNER_SEARCHES } from '../../../../graphql/queries';
import CustomSection from '../../../layout/CustomSection';
import CustomAvatar from '../../../CustomComponents/CustomAvatar';
import { useNavigate } from 'react-router';
import CustomButton from '../../../CustomComponents/CustomButton';
import { People, SearchOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useTournament } from '../TournamentPage';

const Participants = () => {
  const { tournament, id } = useTournament();

  const { t } = useTranslation();
  const { data: statsData, loading: statsLoading } = useQuery(GETENTRIESCOUNT, {
    variables: {
      tournament: id,
    },
  });

  const { data: partnerSearchData } = useQuery(PARTNER_SEARCHES, {
    variables: {
      filter: {
        tournament: id,
        status: 'active',
      },
    },
  });

  const stats = statsData?.registrationCount;
  const partnerSearch = partnerSearchData?.partnerSearches;
  const navigate = useNavigate();
  const uniqueAthletes: any = Array.from(new Map(partnerSearch?.partnerSearches?.map((athlete) => [athlete?.user?.id, athlete])).values()).slice(0, 6);

  return (
    <CustomSection
      icon={<People />}
      title={t('Participants')}
      sx={{ mt: 0 }}
      gap={6}
      titleRightSideElement={
        tournament?.isHideEntries ? null : (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <CustomButton
              size="auto"
              variant="text"
              onClick={() => {
                const currentUrl = new URL(window.location.href);
                currentUrl.searchParams.set('tab', 'participants');
                navigate(currentUrl.pathname + currentUrl.search);
              }}
            >
              <Typography variant="font18" sx={{ textDecoration: 'underline' }} color="primary">
                {t('View all')}
              </Typography>
            </CustomButton>
          </Box>
        )
      }
    >
      <CustomPaperCard sx={{ padding: { xs: '12px', md: '22px' }, display: 'flex', gap: '16px', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '9px' }}>
          <CustomPaperCard
            skeleton={statsLoading}
            sx={{
              width: '100%',
              flex: 1,
              background: '#F9F9F9',
              display: 'flex',
              justifyContent: 'center',
              height: '56px',
              alignItems: 'center',
              gap: '7px',
              flexDirection: 'column',
            }}
          >
            <Typography variant="font21" sx={{ lineHeight: '100%' }}>
              {t('Entries')}
            </Typography>
            <Typography variant="font25" color="primary" sx={{ lineHeight: '100%' }}>
              {stats?.confirmedRequests ?? 0}
            </Typography>
          </CustomPaperCard>
          <CustomPaperCard
            skeleton={statsLoading}
            sx={{
              width: '100%',
              flex: 1,
              background: '#F9F9F9',
              display: 'flex',
              justifyContent: 'center',
              height: '56px',
              alignItems: 'center',
              gap: '7px',
              flexDirection: 'column',
            }}
          >
            <Typography variant="font21" sx={{ lineHeight: '100%' }}>
              {t('Participants')}
            </Typography>
            <Typography variant="font25" color="primary" sx={{ lineHeight: '100%' }}>
              {stats?.participantsCount ?? 0}
            </Typography>
          </CustomPaperCard>
        </Box>
        {partnerSearch?.uniqueTotal > 0 && (
          <>
            <Box sx={{ width: '100%', height: '1px', background: '#DFDFDF' }} />
            <Stack gap="10px">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  maskImage: 'linear-gradient(to right, black, transparent)',
                  WebkitMaskImage: 'linear-gradient(to right, black, transparent 97%)',
                  '& > *:not(:first-child)': { marginLeft: '-8px' },
                }}
              >
                {uniqueAthletes?.map((athlete) => (
                  <CustomAvatar
                    size={24}
                    key={athlete?.user?.id}
                    alt={athlete?.user?.name}
                    src={athlete?.user?.avatar}
                    seed={athlete?.user?.name + ' ' + athlete?.user?.surname}
                    onClick={() => navigate(`/athlete/${athlete?.user?.id}/${athlete?.user?.name}-${athlete?.user?.surname}?tab=overview`)}
                    sx={{ border: '1px solid #FAFAFA' }}
                  />
                ))}
                {partnerSearch?.uniqueTotal > 6 && (
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '24px',
                      width: '24px',
                      background: '#EBEBEB',
                      borderRadius: '999px',
                      border: '1px solid #FAFAFA',
                    }}
                  >
                    <Typography variant="font13">+{partnerSearch?.uniqueTotal - 6}</Typography>
                  </Box>
                )}
              </Box>
              <Typography>
                <Typography color="primary" variant="font22" component="span">
                  {partnerSearch?.uniqueTotal} {partnerSearch?.uniqueTotal === 1 ? 'athlete' : 'athletes'}
                </Typography>
                {''} looking for a partner
              </Typography>
            </Stack>
            <CustomButton
              shape="chip"
              size="small"
              variant="secondary"
              sx={{ borderColor: 'currentcolor', width: 'fit-content', paddingRight: '14px', gap: '4px' }}
              onClick={() => {
                const currentUrl = new URL(window.location.href);
                currentUrl.searchParams.set('tab', 'search');
                navigate(currentUrl.pathname + currentUrl.search);
              }}
            >
              <SearchOutlined sx={{ fontSize: '20px' }} /> {t('Go to Partner Search')}
            </CustomButton>
          </>
        )}
      </CustomPaperCard>
    </CustomSection>
  );
};

export default Participants;
