import { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

interface EventLocationProps {
  latitude?: number;
  longitude?: number;
}

const useEventLocation = ({ latitude, longitude }: EventLocationProps) => {
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API || '',
  });

  useEffect(() => {
    if (!isLoaded || !latitude || !longitude) return;
    setMarkerPosition({ lat: latitude, lng: longitude });
  }, [latitude, longitude, isLoaded]);

  return latitude && longitude ? markerPosition : undefined;
};

export default useEventLocation;
