import React, { memo } from 'react';
import { countryToAlpha2 } from 'country-to-iso';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { getPlatformId } from 'src/constants';

function GetFlag({ country, style }) {
  const isCountryNumber = Number(country);
  let code = countryToAlpha2(!Number.isNaN(isCountryNumber) ? '' : country);
  const fallbackImageSrc = '/images/flags/noFlag.svg';
  if (code?.toLowerCase() == 'ru') {
    code = getPlatformId() == 1 ||getPlatformId()==8 ? 'ru' : 'ru_flag';
  }
  const imageSrc = code ? `/images/flags/${code.toLowerCase()}.svg` : fallbackImageSrc;

  return (
    // set stylattribute to div
    <LazyLoadImage
      alt={country}
      // height={15}
      src={imageSrc}
      // width={15}
      style={{ display: 'flex', alignItems: 'center', ...style }}
      effect="opacity"
    />
  );
}

export default memo(GetFlag);
