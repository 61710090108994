import React, { useEffect, useState } from 'react';
import { Box, Typography, Radio, CircularProgress } from '@material-ui/core';
import PartnerSelectionButton from './PartnerSelectionComp';
import Dropdown from './Dropdown';
import SelectedPlayerDisplay from './SelectedPlayer';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CAPTAINSTEAM, CHECKPARENTCOACH, GET_USER_PARTNERS } from 'src/graphql/queries';
import toast from 'react-hot-toast';
import usePlayerSelection from './usePlayerSelection';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';
import useAuth from 'src/hooks/useAuth';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import TeamsDropDown from './TeamsDropdown';
import { useTranslation } from 'react-i18next';

const StepEmailInput = ({ tournamentId, handleCloseModal }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);

  const { handlePlayerValidation, auth, registrationData, updateRegistrationData } = usePlayerSelection(tournamentId);
  const { loading: checkUserLoading, data: checkUser } = useQuery(CHECKPARENTCOACH, {
    variables: { id: parseInt(auth?.user?.id) },
  });
  // State to track which dropdown (player1 or player2) for each category is open
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const { loading: teamLoading, data: teamsData } = useQuery(CAPTAINSTEAM, {
    skip: !isModalOpen,
    fetchPolicy: 'network-only',
    variables: {
      id: parseInt(user?.id),
      tournamentId: parseInt(tournamentId),
    },
  });

  // Logic to handle when "myself" is selected - only update if not already set to the logged-in user
  useEffect(() => {
    const isTeamSelected = registrationData?.selectedCategories?.some((entry) => entry?.category?.category?.type === 'team');

    if (registrationData.flow === 'myself' && !isTeamSelected) {
      const updatedCategories = registrationData.selectedCategories.map((categoryEntry) => {
        if (categoryEntry.partners?.player1?.id !== auth?.user?.id) {
          return {
            ...categoryEntry,
            partners: {
              ...categoryEntry.partners,
              player1: auth?.user, // Set player1 to the logged-in user
            },
          };
        }
        return categoryEntry; // No update needed
      });

      // Update only if there is a change in player1
      if (JSON.stringify(updatedCategories) !== JSON.stringify(registrationData.selectedCategories)) {
        updateRegistrationData({
          selectedCategories: updatedCategories,
        });
      }
    }
  }, [registrationData.flow, auth?.user, registrationData.selectedCategories, updateRegistrationData]);

  const handleFlowChange = (flow) => {
    updateRegistrationData({ flow });
  };

  const handlePlayerSelect = async (player, isSecondPlayer = false, categoryId) => {
    const selectedCategory = registrationData.selectedCategories.find((c) => c.category?.category?.id === categoryId);
    const existingPlayer = isSecondPlayer ? selectedCategory?.partners?.player1 : selectedCategory?.partners?.player2;

    if (existingPlayer?.email === player?.email) {
      toast.error('Partner cannot be the same.');
      return;
    }

    updateRegistrationData({
      selectedCategories: registrationData.selectedCategories.map((categoryEntry) =>
        categoryEntry?.category?.category?.id === categoryId
          ? {
              ...categoryEntry,
              // Set the player1 or player2 id in the additional questions
              ...(isSecondPlayer
                ? {
                    player2additionalQuestions: categoryEntry?.player2additionalQuestions?.map((question) => ({
                      ...question,
                      user: player?.id,
                    })),
                  }
                : {
                    player1additionalQuestions: categoryEntry?.player1additionalQuestions?.map((question) => ({
                      ...question,
                      user: player?.id,
                    })),
                  }),
              partners: {
                ...categoryEntry?.partners,
                [isSecondPlayer ? 'player2' : 'player1']: player,
              },
            }
          : categoryEntry,
      ),
    });
    setDropdownOpen({}); // Close the dropdown after selection
    setAnchorEl(null);
  };
  const updateTeamsArray = (player, categoryId) => {
    const selectedCategory = registrationData?.selectedCategories?.find((c) => c?.category?.category?.id === categoryId);
    const minLimit = selectedCategory?.category?.category?.minMembers;
    const maxLimit = selectedCategory?.category?.category?.maxMembers;
    if (!selectedCategory) return;

    // Check if the player already exists in the teams array
    const playerExists = selectedCategory?.members?.some((teamPlayer) => teamPlayer.id === player.id);

    // If player exists, remove them; otherwise, add them to the array
    const updatedTeams = playerExists
      ? selectedCategory?.members?.filter((teamPlayer) => teamPlayer.id !== player.id) // Remove player
      : [...selectedCategory.members, player]; // Add player

    if (!playerExists && maxLimit && updatedTeams?.length > maxLimit) {
      toast.error(`You can select up to ${maxLimit} members.`);
      return;
    }

    // Enforce the min limit when removing
    if (playerExists && minLimit && updatedTeams.length < minLimit) {
      toast.error(`You need at least ${minLimit} members.`);
      return;
    }

    // Update the registration data with the modified members array
    updateRegistrationData({
      selectedCategories: registrationData.selectedCategories.map((categoryEntry) =>
        categoryEntry?.category?.category?.id === categoryId
          ? {
              ...categoryEntry,
              members: updatedTeams, // Update teams array
            }
          : categoryEntry,
      ),
    });

    setDropdownOpen({}); // Close the dropdown after selection
    setAnchorEl(null);
  };

  const handleRemovePlayer = (isSecondPlayer = false, categoryId) => {
    updateRegistrationData({
      selectedCategories: registrationData.selectedCategories.map((categoryEntry) =>
        categoryEntry?.category?.category?.id === categoryId
          ? {
              ...categoryEntry,
              partners: {
                ...categoryEntry.partners,
                [isSecondPlayer ? 'player2' : 'player1']: null,
              },
            }
          : categoryEntry,
      ),
    });
  };

  const toggleDropdown = (playerType, categoryId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [`${playerType}-${categoryId}`]: !prevState[`${playerType}-${categoryId}`],
    }));
  };
  const handleClose = () => {
    setIsModalOpen(false);
    handleCloseModal();
  };
  const fetchPartners = (categoryId) => {
    // Query to fetch user partners for a specific category
    const { loading: partnersLoading, data: partnersData } = useQuery(GET_USER_PARTNERS, {
      // fetchPolicy: 'network-only',
      variables: {
        id: parseInt(user?.id),
        filter: { tournament: parseInt(tournamentId), category: parseInt(categoryId) },
      },
    });

    let partners = [];
    if (registrationData?.flow === 'coach') {
      if (checkUser?.checkUserAsParentOrCoach?.isCoachStudents) {
        partners =
          partnersData?.getUserPartners?.students?.map((student) => ({
            id: student?.user?.id,
            name: student?.user?.name,
            surname: student?.user?.surname,
            email: student?.user?.email,
            avatar: student?.user?.avatar,
            validation: student?.validation,
          })) || [];
      } else if (checkUser?.checkUserAsParentOrCoach?.isParentChild) {
        partners =
          partnersData?.getUserPartners?.children?.map((child) => ({
            id: child?.user?.id,
            name: child?.user?.name,
            surname: child?.user?.surname,
            email: child?.user?.email,
            avatar: null,
            validation: child?.validation,
          })) || [];
      }
    } else {
      partners =
        partnersData?.getUserPartners?.friends?.map((friend) => ({
          id: friend?.id,
          name: friend?.name,
          surname: friend?.surname,
          email: friend?.email,
          avatar: friend?.avatar,
          validation: friend?.validation,
        })) || [];
    }

    return { partnersLoading, partners };
  };

  return (
    <Box>
      <Box sx={{ margin: '10px 0', display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
        {['myself', 'coach'].map((flow) => (
          <CustomButton
            key={flow}
            variant="secondary"
            size="small"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              gap: '2px',
              padding: '5px',
              background: '#EDF1F6',
              width: '100%',
              borderRadius: '4px',
              height: '40px',
              textAlign: 'start',
              border: 'none',
            }}
            onClick={() => handleFlowChange(flow)}
          >
            <Radio size="small" checked={registrationData?.flow === flow} />
            <Typography sx={{ lineHeight: '14.52px', color: '#0A2540', whiteSpace: flow === 'coach' ? 'nowrap' : 'normal' }} variant="font15">
              {flow === 'myself' ? t('Myself') : t("I'm a coach/parent")}
            </Typography>
          </CustomButton>
        ))}
      </Box>

      <Box sx={{ margin: '10px 0' }}>
        <Typography sx={{ lineHeight: '14.52px', color: '#0A2540' }} variant="font15">
          {registrationData?.flow === 'coach' ? t('Athlete/s') : t('Partner/s')}
        </Typography>
      </Box>

      {/* Iterate over selected categories */}
      {registrationData?.selectedCategories?.map((categoryEntry) => {
        const { partnersLoading, partners } = fetchPartners(categoryEntry?.category?.category?.id);
        const categoryType = categoryEntry?.category?.category?.type; // Category type (single or doubles)
        // Condition to check flow and category type for button visibility
        const shouldShowButton =
          (registrationData?.flow === 'myself' && categoryType === 'doubles') ||
          (registrationData?.flow === 'coach' && (categoryType === 'doubles' || (!categoryEntry?.partners?.player1 && categoryType === 'single')));

        return (
          <Box key={categoryEntry?.category?.category?.id} sx={{ position: 'relative', marginBottom: '16px', border: '1px solid #EDF1F6', borderRadius: '6px', padding: '8px 10px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
              <Typography
                variant="font18"
                sx={{
                  color: '#0A2540',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  width: '100%',
                }}
              >
                {categoryEntry?.category?.category?.name}
              </Typography>

              {categoryType === 'team' ? (
                <CustomButton
                  variant="primary"
                  size="small"
                  disabled={teamLoading}
                  sx={{
                    backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
                    color: '#fff',
                    width: '140px',
                    height: '35px',
                  }}
                  onClick={() => {
                    setSelectedCategoryId(categoryEntry?.category?.category?.id);
                    setIsModalOpen(true);
                  }}
                >
                  {teamLoading ? <CircularProgress size={20} color="inherit" /> : t('Select Team')}
                </CustomButton>
              ) : (
                shouldShowButton &&
                !(categoryEntry?.partners?.player1 && categoryEntry?.partners?.player2) && (
                  <CustomButton
                    variant="primary"
                    size="small"
                    disabled={categoryEntry?.partners?.player1 && categoryEntry?.partners?.player2} // Disable if both players are selected
                    sx={{
                      backgroundColor: categoryEntry?.partners?.player1 && categoryEntry?.partners?.player2 ? '#EDF1F6' : process.env.REACT_APP_PRIMARY_COLOR,
                      color: categoryEntry?.partners?.player1 && categoryEntry?.partners?.player2 ? '#B0B0B0' : '#FFFFFF',
                      width: '100%',
                      maxWidth: '120px',
                    }}
                    onClick={(event) => toggleDropdown(categoryEntry?.partners?.player1 ? 'player2' : 'player1', categoryEntry?.category?.category?.id)}
                  >
                    {registrationData?.flow === 'coach' ? t('Add Athlete +') : t('Add Partner +')}
                  </CustomButton>
                )
              )}
            </Box>

            {/* Player chips */}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', marginTop: '10px' }}>
              {categoryEntry?.partners?.player1 && (
                <SelectedPlayerDisplay
                  player={categoryEntry?.partners?.player1}
                  onRemove={() => handleRemovePlayer(false, categoryEntry?.category?.category?.id)}
                  isFirstPlayer={true}
                  flow={registrationData?.flow}
                />
              )}
              {categoryEntry?.partners?.player2 && categoryType === 'doubles' && (
                <SelectedPlayerDisplay
                  player={categoryEntry?.partners?.player2}
                  onRemove={() => handleRemovePlayer(true, categoryEntry?.category?.category?.id)}
                  isFirstPlayer={false}
                  flow={registrationData?.flow}
                />
              )}
            </Box>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', marginTop: '10px' }}>
              {categoryEntry?.members?.length > 0 && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px', width: '100%' }}>
                    <img src={categoryEntry?.team?.image} style={{ height: '30px', width: '30px', borderRadius: '6px' }} /> <Typography>{categoryEntry?.team?.title}</Typography>
                  </Box>
                  {categoryEntry?.members?.map((item) => (
                    <SelectedPlayerDisplay player={item} onRemove={() => updateTeamsArray(item, categoryEntry?.category?.category?.id)} isFirstPlayer={false} flow={registrationData?.flow} />
                  ))}
                </>
              )}
            </Box> */}
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px', marginTop: '10px' }}>
              {categoryEntry?.team?.id && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px', width: '100%' }}>
                    <img src={categoryEntry?.team?.image} style={{ height: '30px', width: '30px', borderRadius: '6px' }} /> <Typography>{categoryEntry?.team?.title}</Typography>
                  </Box>
                </>
              )}
            </Box>

            {dropdownOpen[`${'player1'}-${categoryEntry?.category?.category?.id}`] && (
              <CustomModal
                openOvveride={dropdownOpen[`player1-${categoryEntry?.category?.category?.id}`]}
                onClose={() => toggleDropdown('player1', categoryEntry?.category?.category?.id)}
                triggerEl={null}
              >
                <Dropdown
                  players={partners}
                  onSelect={(player) => handlePlayerSelect(player, false, categoryEntry?.category?.category?.id)}
                  isSelectionDisabled={false}
                  tournamentId={tournamentId}
                  partnersLoading={partnersLoading}
                  categoryId={categoryEntry?.category?.id}
                />
              </CustomModal>
            )}

            {dropdownOpen[`${'player2'}-${categoryEntry?.category?.category?.id}`] && categoryType === 'doubles' && (
              <CustomModal
                openOvveride={dropdownOpen[`player2-${categoryEntry?.category?.category?.id}`]}
                onClose={() => toggleDropdown('player2', categoryEntry?.category?.category?.id)}
                triggerEl={null}
              >
                <Dropdown
                  players={partners}
                  onSelect={(player) => handlePlayerSelect(player, true, categoryEntry?.category?.category?.id)}
                  isSelectionDisabled={false}
                  tournamentId={tournamentId}
                  partnersLoading={partnersLoading}
                  categoryId={categoryEntry?.category?.id}
                />
              </CustomModal>
            )}
            <CustomModal title="Select team or create new" openOvveride={isModalOpen} onClose={() => setIsModalOpen(false)} triggerEl={null}>
              <TeamsDropDown
                categoryId={selectedCategoryId}
                Teams={teamsData?.captainTeams || []}
                partnersLoading={teamLoading}
                // onSelect={(team) => updateTeamsArray(team, categoryEntry?.category?.category?.id)}
                category={categoryEntry?.category?.id}
                handleClose={handleClose}
                setIsModalOpen={setIsModalOpen}
                tournamentId={tournamentId}
              />
            </CustomModal>
          </Box>
        );
      })}
    </Box>
  );
};

export default StepEmailInput;
