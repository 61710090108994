import React, { createContext, useContext, useState, ReactNode, useEffect, Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import useAuth from 'src/hooks/useAuth';

interface CategoryEntry {
  category: any;
  partners: {
    player1: any;
    player2: any;
  };
  team: any;
  members: any[];
  player1additionalQuestions: any[];
  player2additionalQuestions: any[];
  additionalFees: { player1: number; player2: number };
  totalAdditionalFees: number;
  currency: string;
}

interface RegistrationEntry {
  selectedCategories: CategoryEntry[]; // Multiple selected categories
  flow: 'myself' | 'coach' | null;
  paymentMethod: 'online' | 'onsite' | 'both' | null;
  totalOnlineFee: number;
  totalOnsiteFee: number;
  payForMyself: boolean;
}

interface TournamentRegistrationContextValue {
  registrationData: RegistrationEntry;
  isLoading: boolean;
  updateRegistrationData: (updatedObject: Partial<RegistrationEntry>) => void;
  resetRegistrationData: () => void;
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const initialRegistrationEntry: RegistrationEntry = {
  selectedCategories: [], // Initialize as an empty array
  totalOnlineFee: 0, // New field for total online fee
  totalOnsiteFee: 0, // New field for total onsite fee
  flow: 'myself',
  paymentMethod: null,
  payForMyself: false,
};

const TournamentRegistrationContext = createContext<TournamentRegistrationContextValue>({
  registrationData: initialRegistrationEntry,
  updateRegistrationData: () => {},
  resetRegistrationData: () => {},
  isLoading: false,
  activeStep: 0,
  setActiveStep: () => {},
});

export const useTournamentRegistrationContext = () => {
  return useContext(TournamentRegistrationContext);
};

interface TournamentRegistrationProviderProps {
  children: ReactNode;
}

export const TournamentRegistrationProvider: React.FC<TournamentRegistrationProviderProps> = ({ children }) => {
  const [registrationData, setRegistrationData] = useState<RegistrationEntry>(initialRegistrationEntry);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const updateRegistrationData = (updatedObject: Partial<RegistrationEntry>) => {
    setRegistrationData((prevData) => ({ ...prevData, ...updatedObject }));
  };

  const resetRegistrationData = () => {
    setRegistrationData(initialRegistrationEntry);
  };

  return (
    <TournamentRegistrationContext.Provider
      value={{
        registrationData,
        updateRegistrationData,
        resetRegistrationData,
        activeStep,
        setActiveStep,
        isLoading,
      }}
    >
      {children}
    </TournamentRegistrationContext.Provider>
  );
};

export default TournamentRegistrationContext;
