import { gql } from '@apollo/client';

const CLUB_BY_ID = gql`
  query club($id: Int!, $filter: ListClubInput) {
    club(id: $id, filter: $filter) {
      country
      id
      clubName
      logo
      country
      city
      address
      contactEmail
      howToFindUs
      contactPhone
      instagramLink
      facebookLink
      twitterLink
      tikTok
      description
      linkedin
      website
      clubMainAdministrator {
        id
      }
      teams {
        id
        title
        country
        members {
          id
          member {
            id
            name
            surname
            email
            role
            nation
            avatar
          }
        }
        sport {
          id
          title
          icon
        }
      }
      members {
        id
        status
        approvedAt
        user {
          id
          name
          surname
          email
          role
          nation
          phone
          avatar
        }
      }
      federation {
        id
      }
      platform {
        id
      }
    }
  }
`;

export default CLUB_BY_ID;
