import { gql } from '@apollo/client';

const GET_USER_PLAN = gql`
  query getUserPlan($userId: Int!) {
    fetchUserPlan(userId: $userId) {
      id
      user {
        id
      }
      plan
    }
  }
`;
export default GET_USER_PLAN;
