import { gql } from '@apollo/client';

const UPDATE_CLUB_USER_REQUEST = gql`
  mutation updateClubMember($updateClubMemberInput: UpdateClubMemberInput!) {
    updateClubMember(updateClubMemberInput: $updateClubMemberInput) {
      id
    }
  }
`;
export default UPDATE_CLUB_USER_REQUEST;
