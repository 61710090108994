import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';
import { useNavigate, useParams } from 'react-router';
import { partnerImges } from 'src/constants';
import SliderWrapper from 'src/components/SliderWrapper';
import GalleryCard from './GalleryCard';
import NoDataFound from '../NoDataFound';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  generalContainer: {
    width: '100%',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  infoText: {
    color: '#0A2540',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  org: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '12',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  orgText: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  },
}));

const LeagueGallery: FC<any> = ({ gallery }: any) => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <React.Fragment>
      <Box sx={{ marginTop: '7px' }}>
        {gallery?.length > 0 ? (
          <SliderWrapper length={gallery?.length} heading="Gallery" toShow={auth.isDrawerOpen ? 2.2 : 4} button="" isFramed={false}>
            {() => {
              return gallery?.map((src, index) => (
                <Box sx={{ padding: { sm: '10px 0 5px 0', xs: '10px 0 10px 0' } }}>
                  <GalleryCard src={src} />
                </Box>
              ));
            }}
          </SliderWrapper>
        ) : (
          <NoDataFound text={t('No photos added')} sx={{ margin: '100px 0' }} />
        )}

        {/* <Box sx={{ width: '100%' }}>
          <Typography sx={{ color: '#0A2540' }} variant="heading17">
            {t('Gallery')}
          </Typography>
          <TournamentGallery loading={galleryLoading} data={gallery} />
        </Box> */}
      </Box>
    </React.Fragment>
  );
};

export default LeagueGallery;
