import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import StepCategorySelection from './CategorySelection';
import StepEmailInput from './SelectPartner';
import StepAdditionalQuestions from './AdditionalQuestions';
import StepSummary from './Summary';
import StepSuccessMessage from './SuccessMessage';
import { StepNavigation } from './Navigation';
import { useTranslation } from 'react-i18next';
import Dot from './Dot';
import useAuth from 'src/hooks/useAuth';
import { GET_PAYMENT_CALCULATIONS, MyENTERIESUPDATED, USER_TOURNAMENT_CATEGORIES } from 'src/graphql/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_TOURNAMENT_REG_PAYMENT, TournamentRegistrations } from 'src/graphql/mutations';
import toast from 'react-hot-toast';
import CustomModal from 'src/components/CustomComponents/CustomModal';
import { getPlatformId } from 'src/constants';
import { useTournament } from '../TournamentPage/TournamentPage';

const steps = ['Category Selection', 'Email Input', 'Additional Questions', 'Summary', 'Success'];

const TournamentRegistrationModal = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id, tournament, refetch: refetchTournament } = useTournament();
  const {
    loading: userCatLoading,
    data: userCat,
    refetch,
  } = useQuery(USER_TOURNAMENT_CATEGORIES, {
    skip: !id,
    fetchPolicy: 'network-only',
    variables: {
      email: user?.email,
      filter: { tournament: id },
    },
  });

  const { registrationData, resetRegistrationData, updateRegistrationData, activeStep, setActiveStep } = useTournamentRegistrationContext();
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [termAccepted, setTermAccepted] = useState(false); // New state for checkbox

  const handleCheckboxChange = () => {
    setTermAccepted(!termAccepted);
  };

  // useEffect(() => {
  //   // Get query params from the URL
  //   const params = new URLSearchParams(location.search);
  //   const partnerSearch = params.get('partnerSearch');
  //   const paramCategory = params.get('category');
  //   const user1 = params.get('user1');
  //   const user2 = params.get('user2');
  //   console.log('partnerSearch', partnerSearch);
  //   console.log('user1', user1);
  //   console.log('user2', user2);
  //   // If partnerSearch exists, automatically select category and users
  //   if (partnerSearch && paramCategory && user1 && user2) {
  //     const selectedCategory = {
  //       category: { id: paramCategory }, // Create a basic category object with the category ID from the query
  //       partners: {
  //         player1: { email: user1 }, // Set user1 as player1
  //         player2: { email: user2 }, // Set user2 as player2
  //       },
  //       player1additionalQuestions: [], // You can populate this dynamically based on your logic
  //       player2additionalQuestions: [],
  //       additionalFees: { player1: 0, player2: 0 }, // Default to 0, adjust as necessary
  //       totalAdditionalFees: 0, // Default value, adjust as necessary
  //       currency: tournament.currency || 'USD', // Set currency from tournament
  //     };

  //     // Update the registration context with the selected category
  //     updateRegistrationData({
  //       selectedCategories: [selectedCategory],
  //     });

  //   }
  // }, []);
  const [registerUser] = useMutation(TournamentRegistrations, {
    refetchQueries: [
      {
        query: MyENTERIESUPDATED,
        variables: {
          filter: {
            users: parseInt(user?.id),
            tournament: id,
          },
        },
      },
    ],
    onCompleted: (data) => {
      refetchTournament();
      refetch();
    },
  });
  const [tournamentPayment] = useMutation(CREATE_TOURNAMENT_REG_PAYMENT);
  const [fetchPayments, { data, loading: calculationsLoading }] = useLazyQuery(GET_PAYMENT_CALCULATIONS, {
    variables: {
      categoryPaymentInput: registrationData?.selectedCategories?.map((categoryEntry) => ({
        categoryId: parseInt(categoryEntry?.category?.category?.id),
        additionalFee: categoryEntry?.totalAdditionalFees,
        onSiteAmount: parseInt(categoryEntry?.category?.onsiteFee),
        currency: tournament?.currency,
        amount: parseInt(categoryEntry?.category?.fee),
        partnerId: parseInt(categoryEntry?.partners?.player2?.id),
      })),
      calculatePaymentInput: {
        tournamentId: id,
        userId: parseInt(user?.id),
        platformId: getPlatformId(),
        payForMyself: registrationData?.payForMyself,
      },
    },
  });
  const [loading, setLoading] = useState(false);
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  // const isDoubleAmount = registrationData?.selectedCategory?.category?.type == 'doubles' && registrationData?.selectedCategory?.onlinePayments?.toLowerCase() == 'per athlete';
  const isEmptyContent = (content) => {
    return !content || content.trim() === '' || /^<p><br><\/p>$/.test(content.trim());
  };
  const handlePayClick = async (method) => {
    // const isTeamSelected = registrationData?.selectedCategories?.some((entry) => entry?.category?.category?.type === 'team');

    try {
      if (tournament?.termsAndConditions && !isEmptyContent(tournament.termsAndConditions) && !termAccepted) {
        toast.error('Please accept terms and conditions');
        return;
      }
      setLoading(true);
      // Create an array of payloads for each selected category
      const createTournamentRegistrationInputs = registrationData.selectedCategories.map((categoryEntry) => {
        let fee;
        if (method == 'online') {
          fee = categoryEntry?.category?.fee ? categoryEntry?.category?.fee : 0;
        } else if (method == 'onsite') {
          fee = categoryEntry?.category?.onsiteFee ? categoryEntry?.category?.onsiteFee : categoryEntry?.category?.fee;
        }
        const onlinePayment = data?.calculatePayments?.calculatedPayments?.find((payment) => payment?.categoryId == parseInt(categoryEntry?.category?.category?.id));
        const onsitePayment = data?.calculatePayments?.calculatedPayments?.find((payment) => payment?.categoryId == parseInt(categoryEntry?.category?.category?.id));

        // If a matching category is found, use the totalPayment for that category
        const totalOnlinePayment = onlinePayment ? onlinePayment?.totalPayment : 0;
        const totalOnsitePayment = onsitePayment ? onsitePayment?.onSiteAmountAndAdditionalFee : 0;

        return {
          ...(registrationData?.paymentMethod == 'both'
            ? {
                amount: method == 'online' ? totalOnlinePayment : null,
              }
            : {
                amount: method == 'online' ? totalOnlinePayment : null,
              }),

          additionalQuestion: [...categoryEntry?.player1additionalQuestions, ...categoryEntry?.player2additionalQuestions],
          payForMyself: registrationData?.payForMyself,
          tournament: tournament?.id,
          categoryId: parseInt(categoryEntry?.category?.id),
          type: categoryEntry?.category?.category?.type || '',
          ...(!categoryEntry?.team?.id
            ? { user: categoryEntry?.partners?.player2?.email ? [categoryEntry?.partners?.player2?.email, categoryEntry?.partners?.player1?.email] : [categoryEntry?.partners?.player1?.email] }
            : { user: [] }),
          // user: isTeamSelected
          //   ? categoryEntry?.members?.map((user) => user?.email)
          //   : categoryEntry?.partners?.player2?.email
          //     ? [categoryEntry?.partners?.player2?.email, categoryEntry?.partners?.player1?.email]
          //     : [categoryEntry?.partners?.player1?.email],
          status: (registrationData?.paymentMethod == 'online' || method == 'online') && categoryEntry?.totalAdditionalFees + categoryEntry?.category?.fee ? 'payment_pending' : 'pending',
          ...(categoryEntry?.totalAdditionalFees && {
            additionalFee: categoryEntry?.totalAdditionalFees,
          }),
          ...(categoryEntry?.team?.id ? { team: parseInt(categoryEntry?.team?.id) } : {}),
          request: '',
          termsAndConditions: true,
          ...(registrationData?.paymentMethod == 'both' && {
            bothPaymentMethod: method == 'online' ? 'online' : 'onsite',
          }),
        };
      });

      const regResponse = await registerUser({
        variables: {
          createTournamentRegistrationInputs,
          platformId: getPlatformId(),
          redirectURL: window.location.origin,
        },
      });
      if (method === 'online' && regResponse?.data?.createTournamentRegistrations?.length) {
        const paymentResponse = await tournamentPayment({
          variables: {
            email: user?.email,
            name: user?.name,
            amount: parseFloat(data?.calculatePayments?.totalPayable),
            platformProcessingFee: data?.calculatePayments?.totalProcessingFee,
            entryFee: data?.calculatePayments?.totalEntryFee,
            totalTournatedServiceFee: data?.calculatePayments?.totalTournatedServiceFee,
            totalPartnerServiceFee: data?.calculatePayments?.totalPartnerServiceFee,
            VATFee: data?.calculatePayments?.totalVatFee,
            successUrl: window.location.href,
            currency: tournament?.currency,
            registrationRequestIds: regResponse?.data?.createTournamentRegistrations?.map((item) => item?.id),
            tournamentName: tournament.title,
            tournamentId: id,
          },
        });

        if (paymentResponse?.data?.createTournamentRegistrationPayment?.url) {
          window.open(`${paymentResponse?.data?.createTournamentRegistrationPayment?.url}`, '_self');
          return; // Early return to stop further execution if redirect happens
        }
      }

      // Move to the final success step after successful registration
      setActiveStep(steps.length - 1);

      toast.success('Registration successful!');
      resetRegistrationData(); // Reset registration data after success
    } catch (error) {
      console.error('Error during registration:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    // if (activeStep === steps.length - 1) {
    resetRegistrationData(); // Reset registration data
    setActiveStep(0); // Reset to the first step
    // }

    handleClose();
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleDotClick = (index) => {
    setActiveStep(index);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepCategorySelection tournament={tournament} tournamentCategories={userCat?.userTournamentCategories} loading={userCatLoading} />;
      case 1:
        return <StepEmailInput tournamentId={id} handleCloseModal={handleCloseModal} />;
      case 2:
        return <StepAdditionalQuestions priceUnit={registrationData?.selectedCategories?.[0]?.currency ?? tournament?.currency} />;
      case 3:
        return <StepSummary tournamentCategories={userCat?.userTournamentCategories} priceUnit={tournament?.currency} calculations={data} isPayForMyselfEnabled={tournament?.isPayForMyselfEnabled} />;
      case 4:
        return <StepSuccessMessage tournament={tournament} />;
      default:
        return <StepCategorySelection tournament={tournament} tournamentCategories={userCat?.userTournamentCategories} loading={userCatLoading} />;
    }
  };
  const getModalTitle = (step) => {
    switch (step) {
      case 0:
        return 'Registration';
      case 1:
        return "Who's signing up";
      case 2:
        return 'Additional Questions';
      case 3:
        return 'Summary';
      case 4:
        return 'Registration';
      default:
        return 'Registration';
    }
  };
  return (
    <CustomModal openOvveride={open} onClose={handleCloseModal} title={t(getModalTitle(activeStep))} handleBack={showBackBtn}>
      {(handleClose, open, backButtonClickCounter, setBackButtonClickCounter) => {
        return (
          <Box
            sx={{
              maxWidth: { xs: 'none', sm: '400px' },
              minWidth: { xs: '100%', sm: '400px' },
              minHeight: { xs: '65dvh', sm: 'auto' },
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {renderStepContent(activeStep)}
            <Box>
              <StepNavigation
                setShowBackBtn={setShowBackBtn}
                backButtonClickCounter={backButtonClickCounter}
                setBackButtonClickCounter={setBackButtonClickCounter}
                activeStep={activeStep}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
                loading={loading}
                handlePayClick={handlePayClick}
                setActiveStep={setActiveStep}
                tournament={tournament}
                priceUnit={registrationData?.selectedCategories?.[0]?.currency ?? tournament?.currency}
                fetchPayments={fetchPayments}
                calculationsLoading={calculationsLoading}
                data={data}
                termAccepted={termAccepted}
                handleCheckboxChange={handleCheckboxChange}
                isEmptyContent={isEmptyContent}
                handleCloseModal={handleCloseModal}
              />

              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {[0, 1, 2, 3, 4].map((index) => (
                  <Dot key={index} active={activeStep === index} onClick={() => console.log(index)} />
                ))}
              </Box>
            </Box>
          </Box>
        );
      }}
    </CustomModal>
  );
};

export default TournamentRegistrationModal;
