import { FC, Fragment } from 'react';
import { Box, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import Scrollbar from '../../../Scrollbar';
import { ReactSVG } from 'react-svg';
import { useQuery } from '@apollo/client';
import { ORDEROFPLAY } from 'src/graphql/queries';
import { useNavigate, useParams } from 'react-router';
import GetFlag from 'src/utils/getFlags';
import { formatSeed } from 'src/constants';
import Loading from 'src/components/Loading';
import { downloadFile, getShortMatchStatus } from 'src/utils/helperFunction';
import NoDataFound from 'src/components/dashboard/NoDataFound';
import { useTranslation } from 'react-i18next';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import chroma from 'chroma-js';
import LiveIcon from 'src/components/CustomComponents/LiveIcon';
import toast from 'react-hot-toast';
import { DownloadSharp } from '@material-ui/icons';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import DownloadOOP from '../groupsTable/DownloadOOP';
import TeamComponent from 'src/components/shared/TeamComponent';

const useStyles = makeStyles((theme) => ({
  courtTitle: {
    color: '#193660',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    padding: '5px 8px 5px 8px',
    borderRadius: '4px',
    border: '1px solid #EDF1F6',
    backgroundColor: '#FAFAFA',
    whiteSpace: 'nowrap',
  },
  matchTime: {
    color: '#0A2540',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  vs: {
    fontFamily: 'Inter, sans-serif',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FAFAFA',
    color: '#0A2540',
    borderRadius: '50%',
    fontWeight: 600,
    fontSize: '12px',
  },
  teamBox: {
    position: 'relative',
    border: '1px solid #EDF1F6',
    padding: '5px 10px',
    borderRadius: '6px',
    width: '100%',
  },
  teamBoxWinner: {
    border: `1px solid ${theme.palette.primary.main} !important`,
  },
  winnerBadge: {
    fontFamily: 'Inter, sans-serif',
    position: 'absolute',
    top: 0,
    right: 0,
    background: theme.palette.primary.main,
    padding: '0px 5px',
    borderRadius: '0px 6px',
    color: '#fff',
    fontSize: '10px',
  },
  titles: {
    color: 'var(--bright-blue-60, rgba(27, 56, 97, 0.60))',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  looserTitle: {
    color: '#193660',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    display: 'flex !important',
  },
  winnerTitle: {
    color: '#193660',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    display: 'flex !important',
  },
  ponits: {
    color: '#193660',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  },
}));

const DrawScore = ({ score, scoreType }) => {
  const regex = /(\[?\(?\d+:\d+\)?\]?)/g;
  const matches = score.match(regex) || [];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '12px',
        color: '#0A2540',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '12px',
        textTransform: 'uppercase',
        border: '1px solid #EDF1F6',
        borderRadius: '6px',
        width: '60%',
      }}
    >
      {matches.map((pair, index) => {
        const numbers = pair.replace(/[\[\]\(\)]/g, '').split(':');
        return pair.includes('[') ? (
          <Fragment key={index}>
            [<Box sx={{ color: (theme) => (numbers?.[0] >= numbers?.[1] ? theme.palette.primary.main : null) }}>{numbers?.[0]}</Box>:
            <Box sx={{ color: (theme) => (numbers?.[1] >= numbers?.[0] ? theme.palette.primary.main : null) }}>{numbers?.[1]}</Box>]
          </Fragment>
        ) : pair.includes('(') ? (
          <Fragment key={index}>
            (<Box sx={{ color: (theme) => (numbers?.[0] >= numbers?.[1] ? theme.palette.primary.main : null) }}>{numbers?.[0]}</Box>:
            <Box sx={{ color: (theme) => (numbers?.[1] >= numbers?.[0] ? theme.palette.primary.main : null) }}>{numbers?.[1]}</Box>
            <Box sx={{ marginRight: '5px' }}>)</Box>
          </Fragment>
        ) : (
          <Fragment key={index}>
            <Box sx={{ color: (theme) => (numbers?.[0] >= numbers?.[1] ? theme.palette.primary.main : null) }}>{numbers?.[0]}</Box>:
            <Box sx={{ marginRight: '5px', color: (theme) => (numbers?.[1] >= numbers?.[0] ? theme.palette.primary.main : null) }}>{numbers?.[1]}</Box>
          </Fragment>
        );
      })}
      {`${scoreType ? '  ' + scoreType : ''}`}
    </Box>
  );
};

const getFormatedScore = (inputScore, match, scoreType) => {
  const scoresByRound =
    Array.isArray(inputScore) &&
    inputScore?.reduce((acc, score) => {
      const round = score?.round;
      if (!acc[round]) {
        acc[round] = [];
      }
      acc[round]?.push(score);
      return acc;
    }, {});

  let formattedScoresJSX = [];

  if (typeof scoresByRound === 'object' && scoresByRound !== null) {
    Object?.keys(scoresByRound)?.forEach((round, index) => {
      const scoresForRound = scoresByRound[round];
      const scoresJSX = [];

      const entry1 = scoresForRound[0];
      const entry2 = scoresForRound[1];

      if (entry1 && entry2) {
        if (entry1?.entry.id === match?.entry1?.id && entry2?.entry?.id === match?.entry2?.id) {
          entry1.roundFormat === 'tie'
            ? scoresJSX.push(
                <Fragment key={index}>
                  [
                  <Box key={index} sx={{ color: match?.winner?.id === entry1?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>
                    {entry1.score}
                  </Box>
                  :<Box sx={{ color: match?.winner?.id === entry2?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>{entry2.score}</Box>]
                </Fragment>,
              )
            : entry1.roundFormat === 'roundTie'
            ? scoresJSX.push(
                <Fragment key={index}>
                  <Box sx={{ marginLeft: '-5px' }}>(</Box>
                  <Box key={index} sx={{ color: match?.winner?.id === entry1?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>
                    {entry1.score}
                  </Box>
                  :<Box sx={{ color: match?.winner?.id === entry2?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>{entry2.score}</Box>
                  <Box sx={{ marginRight: '5px' }}>)</Box>
                </Fragment>,
              )
            : scoresJSX.push(
                <Fragment key={index}>
                  <Box key={index} sx={{ color: match?.winner?.id === entry1?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>
                    {entry1.score}
                  </Box>
                  :<Box sx={{ color: match?.winner?.id === entry2?.entry?.id ? (theme) => theme.palette.primary.main : '', marginRight: '5px' }}>{entry2.score}</Box>
                </Fragment>,
              );
        } else {
          entry2.roundFormat === 'tie'
            ? scoresJSX.push(
                <Fragment key={index}>
                  [
                  <Box key={index} sx={{ color: match?.winner?.id === entry2?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>
                    {entry2.score}
                  </Box>
                  :<Box sx={{ color: match?.winner?.id === entry1?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>{entry1.score}</Box>]
                </Fragment>,
              )
            : entry2.roundFormat === 'roundTie'
            ? scoresJSX.push(
                <Fragment key={index}>
                  <Box sx={{ marginLeft: '-5px' }}>(</Box>
                  <Box key={index} sx={{ color: match?.winner?.id === entry2?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>
                    {entry2.score}
                  </Box>
                  :<Box sx={{ color: match?.winner?.id === entry1?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>{entry1.score}</Box>
                  <Box sx={{ marginRight: '5px' }}>)</Box>
                </Fragment>,
              )
            : scoresJSX.push(
                <Fragment key={index}>
                  <Box key={index} sx={{ color: match?.winner?.id === entry2?.entry?.id ? (theme) => theme.palette.primary.main : '' }}>
                    {entry2.score}
                  </Box>
                  :<Box sx={{ color: match?.winner?.id === entry1?.entry?.id ? (theme) => theme.palette.primary.main : '', marginRight: '5px' }}>{entry1.score}</Box>
                </Fragment>,
              );
        }
      }
      // Add space or any separator if needed between rounds
      if (scoresJSX.length > 0) {
        formattedScoresJSX.push(scoresJSX);
        if (index < Object.keys(scoresByRound).length - 1) {
          formattedScoresJSX.push(' '); // Add a separator between rounds
        }
      }
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '12px',
        color: '#0A2540',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        fontSize: '12px',
        textTransform: 'uppercase',
        border: '1px solid #EDF1F6',
        borderRadius: '6px',
        width: '90%',
      }}
    >
      {formattedScoresJSX}
      {`${scoreType ? '  ' + scoreType : ''}`}
    </Box>
  );
};

const PlayerComponent = ({ user, player1 = false, entry = null, segment = null }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <GetFlag country={user?.nation} style={{ width: '20px', height: '20px', marginTop: '0px', marginRight: '10px' }} />

      <Typography
        sx={{
          display: 'flex',
          cursor: 'pointer',
          textTransform: 'uppercase',
          fontFamily: 'Inter, sans-serif',
          fontSize: '12px',
          fontWeight: 500,
          marginTop: '2px',
        }}
        onClick={() => {
          window.open(`/athlete/${user?.id}/${user?.name}-${user?.surname}?tab=overview`, '_blank');
        }}
      >
        {`${user?.name} ${user?.surname}`}
        {player1 && (entry?.seed || entry?.assignedSegment) && formatSeed({ seed: entry?.seed, assignedSegment: entry?.assignedSegment, additionalSegment: entry?.additionalSegment }, segment)}
      </Typography>
    </Box>
  );
};

function NoScore({ scoreType }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex' }}>
      <ReactSVG style={{ marginRight: '8px' }} src="/images/matchNotSet.svg" />
      <Typography
        sx={{
          display: 'flex',
          color: '#19366066',
          fontFamily: 'Roboto',
          fontWeight: '500',
          fontSize: '12px',
          textTransform: 'uppercase',
        }}
      >
        {scoreType ?? ''}
      </Typography>
    </Box>
  );
}

const Orderofplaytable: FC<any> = ({ oopData, show, tournamentDocuments }) => {
  const classes = useStyles();

  const shortRoundName = (roundName) => {
    switch (roundName) {
      case 'Semi-Final':
        return 'SF';
      case 'Quarter-Final':
        return 'QF';
      default:
        return roundName;
    }
  };

  return (
    <Box
      sx={{
        // backgroundColor: "background.default",
        // minHeight: "100%",
        borderRadius: '12px',
        background: '#fff',
        mt: 1.2,
        padding: '9px',
      }}
    >
      {/* <CardHeader title="Order items" /> */}
      {show && oopData?.length > 0 ? (
        oopData?.map((match: any, indexKey) => {
          const isTeam = (match?.tournamentCategory || match?.group?.tournamentCategory)?.category?.type === 'team';

          return (
            <Grid container sx={{ py: 2.5, borderBottom: '1px solid #EDF1F6' }} direction="row" justifyContent="center">
              <Grid item xs={6} md={2} sx={{ mb: { xs: '10px', md: '0' } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', padding: '5px 0px' }}>
                  <Tooltip title={`${getShortMatchStatus(match?.matchStatus, '', match?.time, true) ?? ''}`}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Typography className={classes.matchTime}>{`${getShortMatchStatus(match?.matchStatus, '', match?.time, true) ?? ''}`}</Typography>
                      <Typography className={classes.courtTitle}>{match?.court?.name}</Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  {match?.isMatchInProgress && (
                    <CustomChip
                      variant="primary"
                      size="small"
                      color={chroma(process.env.REACT_APP_PRIMARY_COLOR ?? '#FF5733')
                        .alpha(0.7)
                        .hex()}
                      sx={{ gap: '3px', height: '20px' }}
                    >
                      <LiveIcon />
                      live
                    </CustomChip>
                  )}
                  <Tooltip
                    title={
                      match?.group?.name ||
                      match?.draw?.title + ` - ${match?.group ? '' : shortRoundName(match?.round)} (${match?.tournamentCategory?.category?.name || match?.group?.tournamentCategory?.category?.name})`
                    }
                    placement="bottom-start"
                  >
                    <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', color: '#0A254059' }}>
                      {match?.group?.name || match?.draw?.title}
                      {` - ${match?.group ? '' : shortRoundName(match?.round)} (${match?.tournamentCategory?.category?.name || match?.group?.tournamentCategory?.category?.name})`}
                    </Typography>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={6} md={2} sx={{ mb: { xs: '10px', md: '0' } }} container alignItems="center" justifyContent="center">
                {match?.score ? (
                  typeof match?.score === 'string' ? (
                    <>
                      <DrawScore score={match?.score} scoreType={match?.scoreType} />
                    </>
                  ) : (
                    <>{getFormatedScore(match?.score, match, match?.scoreType)}</>
                  )
                ) : (
                  <NoScore scoreType={match?.scoreType} />
                )}
              </Grid>
              <Grid item xs={12} md={3} sx={{ marginLeft: { xs: '37px', md: '0' } }} container alignItems="center">
                <Box className={`${classes.teamBox} ${match?.winner?.id === match?.entry1?.id && classes.teamBoxWinner}`}>
                  {isTeam ? (
                    <>
                      <TeamComponent
                        team={match?.entry1?.team}
                        users={match?.entry1?.users}
                        showTeamMembers={(match?.tournamentCategory || match?.group?.tournamentCategory)?.category?.identifyTeamMember}
                      />
                      {match?.entry1partner?.registrationRequest?.team?.id && (
                        <>
                          <Divider light sx={{ mb: '5px', mt: '5px' }} />
                          <TeamComponent
                            team={match?.entry1partner?.registrationRequest?.team}
                            users={match?.entry1partner?.registrationRequest?.users}
                            showTeamMembers={(match?.tournamentCategory || match?.group?.tournamentCategory)?.category?.identifyTeamMember}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {match?.entry1?.users?.length > 0 ? (
                        <>
                          {match?.winner?.id === match?.entry1?.id && <Box className={classes.winnerBadge}>Winner</Box>}
                          <PlayerComponent user={match?.entry1?.users?.[0]?.user} player1 entry={match?.entry1} segment={match?.group?.segment} />
                        </>
                      ) : (
                        <Typography
                          sx={{
                            display: 'flex',
                            color: '#19366066',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            fontSize: '12px',
                            textTransform: 'uppercase',
                          }}
                        >
                          TBA
                        </Typography>
                      )}
                      {match?.entry1?.users?.length > 1 && (
                        <>
                          <Divider light />
                          {match?.entry1?.users?.[1] ? (
                            <PlayerComponent user={match?.entry1?.users?.[1]?.user} />
                          ) : (
                            <Typography
                              sx={{
                                display: 'flex',
                                color: '#19366066',
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '12px',
                                textTransform: 'uppercase',
                              }}
                            >
                              TBA
                            </Typography>
                          )}
                        </>
                      )}

                      {match?.entry1partner?.registrationRequest?.users?.length > 0 &&
                        match?.entry1partner?.registrationRequest?.users?.map((user: any, index) => (
                          <>
                            <Divider light />
                            <PlayerComponent user={match?.entry1partner?.registrationRequest?.users?.[0]?.user} />
                          </>
                        ))}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={2} sx={{ my: { xs: '10px', md: '0' }, justifyContent: { xs: 'start', md: 'center' }, alignItems: 'center' }} container>
                <Box className={classes.vs}>VS</Box>
              </Grid>
              <Grid item xs={12} md={3} sx={{ marginLeft: { xs: '37px', md: '0' } }} container alignItems="center">
                <Box className={`${classes.teamBox} ${match?.winner?.id === match?.entry2?.id && classes.teamBoxWinner}`}>
                  {isTeam ? (
                    <>
                      <TeamComponent
                        team={match?.entry2?.team}
                        users={match?.entry2?.users}
                        showTeamMembers={(match?.tournamentCategory || match?.group?.tournamentCategory)?.category?.identifyTeamMember}
                      />
                      {match?.entry2partner?.registrationRequest?.team?.id && (
                        <>
                          <Divider light sx={{ mb: '5px', mt: '5px' }} />
                          <TeamComponent
                            team={match?.entry2partner?.registrationRequest?.team}
                            users={match?.entry2partner?.registrationRequest?.users}
                            showTeamMembers={(match?.tournamentCategory || match?.group?.tournamentCategory)?.category?.identifyTeamMember}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {match?.entry2?.users?.length > 0 ? (
                        <>
                          {match?.winner?.id === match?.entry2?.id && <Box className={classes.winnerBadge}>Winner</Box>}
                          <PlayerComponent user={match?.entry2?.users?.[0]?.user} player1 entry={match?.entry2} segment={match?.group?.segment} />
                        </>
                      ) : (
                        <Typography
                          sx={{
                            display: 'flex',
                            color: '#19366066',
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            fontSize: '12px',
                            textTransform: 'uppercase',
                          }}
                        >
                          TBA
                        </Typography>
                      )}
                      {match?.entry2?.users?.length > 1 && (
                        <>
                          <Divider light />
                          {match?.entry2?.users?.[1] ? (
                            <PlayerComponent user={match?.entry2?.users?.[1]?.user} />
                          ) : (
                            <Typography
                              sx={{
                                display: 'flex',
                                color: '#19366066',
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                fontSize: '12px',
                                textTransform: 'uppercase',
                              }}
                            >
                              TBA
                            </Typography>
                          )}
                        </>
                      )}

                      {match?.entry2partner?.registrationRequest?.users?.length > 0 &&
                        match?.entry2partner?.registrationRequest?.users?.map((user: any, index) => (
                          <>
                            <Divider light />
                            <PlayerComponent user={match?.entry2partner?.registrationRequest?.users?.[0]?.user} />
                          </>
                        ))}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          );
        })
      ) : tournamentDocuments?.length > 0 ? (
        <DownloadOOP tournamentDocuments={tournamentDocuments} />
      ) : (
        <NoDataFound text="There is no available order of play" sx={{ margin: '150px 0' }} />
      )}
    </Box>
  );
};

export default Orderofplaytable;
