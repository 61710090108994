import { gql } from '@apollo/client';
const USER_LICENSES = gql`
  query validLicensesForUsers($filter: ListLicenseInput, $platform: Int) {
    validLicensesForUsers(filter: $filter, platform: $platform) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
      cantBuy
      errorMessage
      additionalQuestions {
        id
        question
        fieldType
        fieldOptions
        askEachAthlete
      }
    }
  }
`;
export default USER_LICENSES;
