import { getTimezone } from './getTimezone';
import moment from 'moment';
import { TimeZone } from '@vvo/tzdb';

/**
 * Adjusts event dates and times to the specified timezone.
 * Handles the conversion of date strings into `moment` objects
 * for consistent event scheduling.
 */
const useLocalizedEvent = <T extends Record<string, any> | undefined, K extends keyof T>(event: T | undefined, dateKeys: K[]) => {
  if (!event) return;

  const timezone = getTimezone(event?.timeZone);
  const localTime = () => moment.tz(timezone.name);
  const dateFormats = ['YYYY-MM-DD', 'MM/DD/YYYY', 'DD-MM-YYYY', moment.ISO_8601];

  /**
   * Converts a UTC date string or Date object to a `moment` object
   * in the specified timezone.
   */
  const getLocalDate = (utcDate: string | Date) => {
    if (!utcDate) return undefined;
    return moment.tz(utcDate, timezone.name).subtract(timezone.rawOffsetInMinutes, 'minutes');
  };

  const dates = dateKeys.reduce((acc, key) => {
    const dateValue = event[key];
    if (moment(dateValue, dateFormats, true).isValid()) {
      acc[key] = getLocalDate(dateValue);
    } else {
      acc[key] = undefined;
    }
    return acc;
  }, {} as Record<(typeof dateKeys)[number], moment.Moment | undefined>);

  const newEvent = {
    ...event,
    ...dates,
  };

  const isOngoing = !!(newEvent?.startDate && newEvent.endDate) ? localTime().isAfter(newEvent.startDate) && localTime().isBefore(newEvent.endDate) : false;
  const isStarted = !!newEvent?.startDate ? localTime().isAfter(newEvent.startDate) : false;
  const isFinished = !!newEvent?.endDate ? localTime().isAfter(newEvent.endDate) : false;
  const isRegistrationClosed = !!newEvent?.entryDeadline ? localTime().isAfter(newEvent.entryDeadline) : false;
  const isRegistrationUpcoming = !!newEvent?.registrationStartDate ? localTime().isBefore(newEvent.registrationStartDate) : false;
  return { ...newEvent, getLocalDate, isOngoing, isStarted, isFinished, isRegistrationClosed, isRegistrationUpcoming, localTime, timeZone: timezone };
};

export default useLocalizedEvent;

export type LocalizedEvent<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? moment.Moment : T[P];
} & { isOngoing: boolean; isStarted: boolean; isFinished: boolean; isRegistrationClosed: boolean; isRegistrationUpcoming: boolean; timeZone: TimeZone; localTime: () => moment.Moment };
