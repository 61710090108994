import { gql } from '@apollo/client';

const CREATEPLAN = gql`
  mutation CreateUserPlan($user: Int!, $plan: String!) {
    createUserPlan(createUserPlanInput: { user: $user, plan: $plan }) {
      id
      plan
      user {
        id
      }
    }
  }
`;

export default CREATEPLAN;
