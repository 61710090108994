import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CustomSection from 'src/components/layout/CustomSection';
import { useTranslation } from 'react-i18next';
import CustomPaperCard from 'src/components/CustomComponents/CustomPaperCard';
import moment from 'moment/moment';
import CustomSchedule from '../../../CustomComponents/CustomSchedule';
import { useTournament } from '../TournamentPage';

const useStyles = makeStyles((theme) => ({
  calendarWrapper: {
    width: '100%',
    overflowX: 'auto',
    padding: '8px',
    [theme.breakpoints.down('sm')]: {
      '& .fc-timegrid': {
        fontSize: '10px',
      },
      '& .fc-toolbar-title': {
        fontSize: '12px !important',
      },
      '& .fc-toolbar button': {
        fontSize: '12px',
        padding: '5px 10px',
      },
    },
  },
}));

const ScheduleForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tournament } = useTournament();

  const events = (tournament?.events ? JSON.parse(tournament.events) : []).map((event) => ({
    Id: event.id,
    Subject: event.title,
    StartTime: moment(event.start).format('YYYY-MM-DDTHH:mm:ss'),
    EndTime: moment(event.end).format('YYYY-MM-DDTHH:mm:ss'),
    IsAllDay: false,
    CategoryColor: event.color,
  }));

  return (
    <Box className={classes.calendarWrapper}>
      <CustomSection title={t('Schedule')}>
        <CustomPaperCard sx={{ minHeight: '150px', width: '100%' }}>
          <CustomSchedule data={events} height="auto" selectedDate={tournament?.startDate ? moment(tournament.startDate).toDate() : new Date()} />
        </CustomPaperCard>
      </CustomSection>
    </Box>
  );
};

export default ScheduleForm;
