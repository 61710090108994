import { useMutation } from '@apollo/client';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { getPlatformId, DEV_URL } from 'src/constants';
import { CREATEPLATFORM, REGISTER } from 'src/graphql/mutations';
import useAuth from 'src/hooks/useAuth';

interface RegisterContextValue {
  registerObject: {
    email: string;
    name: string;
    surname: string;
    password: string;
    reEnter: string;
    role: any;
    phone: string;
    // nation: any;
    citizenship: string;
    // city:string;
    dob: any;
    gender: string;
    fideId: string;
    ipinId: string;
    duprId: string;
    telId: string;
    atpId: string;
    wtaId: string;
    pdlId: string;
    isKid: boolean;
    ltsU10Id: string;
    policy: boolean;
    national_fisher_id: string;
    no_fisher_id_reason: string;
    has_fisher_id: boolean;
    user_sports: any;
    no_fisher_id_doc: string;
    additionalUserRole?: any;
    clubRequests?: any;
  };
  isLoading: boolean;
  response?: any;
  updateRegisterObject: (updatedObject: { [key: string]: string | boolean | object }) => void;
  registeration: (requestData: any) => Promise<void>;
}

const initialregisterObject = {
  email: '',
  name: '',
  surname: '',
  password: '',
  reEnter: '',
  role: 'athlete',
  phone: '',
  // nation: "",
  citizenship: '',
  // city:"",
  dob: '',
  gender: '',
  policy: false,
  national_fisher_id: '',
  no_fisher_id_reason: '',
  has_fisher_id: false,
  user_sports: [],
  no_fisher_id_doc: '',
  fideId: '',
  ipinId: '',
  duprId: '',
  telId: '',
  atpId: '',
  wtaId: '',
  ltsU10Id: '',
  pdlId: '',
  isKid: false,
  additionalUserRole: [],
  submit: null,
  clubRequests: [],
};

const RegisterContext = createContext<RegisterContextValue>({
  registerObject: initialregisterObject,
  updateRegisterObject: () => {},
  registeration: async () => {},
  isLoading: false,
});

export const useRegisterContext = () => {
  return useContext(RegisterContext);
};

interface RegisterProviderProps {
  children: ReactNode;
}

export const RegisterProvider: React.FC<RegisterProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [registerObject, setregisterObject] = useState<typeof initialregisterObject>(initialregisterObject);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<any>(null); // Initialize response state
  const [registerUser, { data, loading, error }] = useMutation(REGISTER) as any;

  const { register } = useAuth() as any;
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const updateRegisterObject = (updatedObject: { [key: string]: string | boolean }) => {
    setregisterObject({ ...registerObject, ...updatedObject });
  };
  const registeration = async (requestData: any) => {
    // const clubRequests =
    try {
      const updatedVales = {
        ...registerObject,
        ['additionalUserRole']: JSON.stringify(registerObject?.additionalUserRole),
        platformId: getPlatformId(),
        redirectURL: window?.location?.origin,
        nation: registerObject?.citizenship,
        clubRequests: registerObject?.clubRequests?.map((item) => {
          return {
            clubId: item?.id,
            status: item?.isAdminVerificationRequired ? 'confirm' : 'pending',
          };
        }),
      };
      const response = await registerUser({
        variables: updatedVales,
      });
      await register(response.data.signUp);
      setResponse(response.data.signUp);
      navigate(`/userSettings`);
      setregisterObject(initialregisterObject);
    } catch (error) {
      // setregisterObject(initialregisterObject);
      console.error('Error making API request:', error);
      const err = error?.graphQLErrors;
      toast.error(err[0].message);
    }
  };
  return (
    <RegisterContext.Provider
      value={{
        registerObject,
        updateRegisterObject,
        registeration,
        isLoading,
        response,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterContext;
