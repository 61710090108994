import { gql } from '@apollo/client';

const USER_CLUBS = gql`
  query clubMembers($filter: ListClubMembers, $page: Int, $limit: Int, $clubName: String) {
    clubMembers(filter: $filter, page: $page, limit: $limit, clubName: $clubName) {
      clubMembers {
        id
        status
        createdAt
        user {
          id
          name
          surname
          nation
          avatar
        }
        userRole
        club {
          clubName
          id
          clubMainAdministrator {
            id
          }
        }
      }
      total
    }
  }
`;

export default USER_CLUBS;
